import PublishApi from "../api/publishApi";
import { PUBLISH_DATASET_STATUS } from "../config";
import showToast, { TOAST_TYPE } from "../utils/toastUtlis";
import { PUBLISH_ACTIONS } from "./actionTypes";

export function addPublishDataset(payload) {
  return { type: PUBLISH_ACTIONS.PUBLISH_DATASET_ADD, payload: payload };
}

export function resetPublishDataset() {
  return { type: PUBLISH_ACTIONS.RESET_PUBLISH_DATASET };
}

export function loadingPublishDataset() {
  return { type: PUBLISH_ACTIONS.PUBLISH_DATASET_LOADING };
}

export function errorPublishDataset(payload) {
  return { type: PUBLISH_ACTIONS.PUBLISH_DATASET_ERROR, payload: payload };
}

export function uploadPublishDataset(payload) {
  return { type: PUBLISH_ACTIONS.PUBLISH_DATASET_UPLOAD, payload: payload };
}

export function loadingUploadPublishDataset() {
  return { type: PUBLISH_ACTIONS.PUBLISH_DATASET_UPLOAD_LOADING };
}

export function datasetListLoading() {
  return { type: PUBLISH_ACTIONS.DATASET_LIST_LOADING };
}

export function datasetListSuccess(payload) {
  return { type: PUBLISH_ACTIONS.DATASET_LIST_SUCCESS, payload };
}

export function datasetsListError() {
  return { type: PUBLISH_ACTIONS.DATASET_LIST_ERROR };
}

export function publishDatasetDetailsLoading() {
  return { type: PUBLISH_ACTIONS.PUBLISH_DATASET_DETAILS_LOADING };
}

export function publishDatasetDetailsSuccess(payload) {
  return { type: PUBLISH_ACTIONS.PUBLISH_DATASET_DETAILS_SUCCESS, payload };
}

export function publishDatasetDetailsError() {
  return { type: PUBLISH_ACTIONS.PUBLISH_DATASET_DETAILS_ERROR };
}

export function errorUploadPublishDataset(payload) {
  return {
    type: PUBLISH_ACTIONS.PUBLISH_DATASET_UPLOAD_ERROR,
    payload: payload,
  };
}

export function editDescriptionLoading() {
  return { type: PUBLISH_ACTIONS.UPDATED_DESCRIPTION_LOADING };
}

export function editDescriptionSuccess(payload) {
  return { type: PUBLISH_ACTIONS.UPDATED_DESCRIPTION_SUCCESS, payload };
}

export function editDescriptionError() {
  return { type: PUBLISH_ACTIONS.UPDATED_DESCRIPTION_ERROR };
}

export function updatedBasicInfoLoading() {
  return { type: PUBLISH_ACTIONS.UPDATED_BASIC_INFO_LOADING };
}

export function updatedBasicInfoSuccess(payload) {
  return { type: PUBLISH_ACTIONS.UPDATED_BASIC_INFO_SUCCESS, payload };
}

export function updatedBasicInfoError() {
  return { type: PUBLISH_ACTIONS.UPDATED_BASIC_INFO_ERROR };
}

export function publishDataset(searchParams, page = 1) {
  return async (dispatch) => {
    // wait while data is being fetch
    dispatch(loadingPublishDataset());
    // call the API to get data and then send data to reducer
    try {
      const data = await PublishApi.getDatasetToBePublish(searchParams, page);

      dispatch(addPublishDataset({ status: data.status, data: data.data }));
    } catch (error) {
      // if API call fail dispatch the error action
      console.error(`error while fetching the datasets ${error}`);
      dispatch(errorPublishDataset(error));
    }
  };
}

export function uploadDataset(child_dataset_id, status, license_slug, uid) {
  return async (dispatch) => {
    // wait while data is being upload
    dispatch(loadingUploadPublishDataset());
    // upload the dataset
    try {
      // upload the dataset to publish
      let requestObj = {
        child_dataset_id,
        status,
        license_slug,
        license_url: "",
      };
      const data = await PublishApi.uploadDatasetToPublish(requestObj, uid);
      dispatch(uploadPublishDataset({ status: data.status }));
      dispatch(getPublishDatasetsList(1));
      // notifing the user base on dataset status
      if (status === PUBLISH_DATASET_STATUS.DRAFT) {
        showToast("Your dataset has been successfully added.", TOAST_TYPE.INFO);
      } else if (status === PUBLISH_DATASET_STATUS.PUBLISHED) {
        showToast(
          "Your dataset has been successfully published.",
          TOAST_TYPE.SUCCESS
        );
      } else if (status === PUBLISH_DATASET_STATUS.UNPUBLISHED) {
        showToast(
          "Your dataset has been successfully Unpublished.",
          TOAST_TYPE.ERROR
        );
      }
    } catch (error) {
      // triggred after failing the upload
      console.error(error);
      dispatch(errorUploadPublishDataset(error));
    }
  };
}

// action to fetch the list of published datasets with pagination
export function getPublishDatasetsList(page) {
  return async (dispatch) => {
    // wait while data is being fetch
    dispatch(datasetListLoading());
    try {
      // API call to fetch the dataset list for the given page
      const data = await PublishApi.getDatasetsList(page);
      // dispatching a success action with the fetched dataset list
      dispatch(datasetListSuccess(data.data));
    } catch (error) {
      console.error(`error while fetching the data ${error}`);
      // dispatching an error action in case of failure
      dispatch(datasetsListError());
    }
  };
}

// action to fetch the details of a specific published dataset by ID
export function getPublishedDatasetDetails(publish_id) {
  return async (dispatch) => {
    // wait while data is being fetch
    dispatch(publishDatasetDetailsLoading());
    try {
      //API call to fetch the dataset details for the given ID
      const data = await PublishApi.getPublishedDatasetDetails(publish_id);

      // dispatching a success action with the fetched dataset details
      dispatch(publishDatasetDetailsSuccess(data));
    } catch (error) {
      console.error(`error while fetching the data ${error}`);
      // dispatching an error action in case of failure
      dispatch(publishDatasetDetailsError());
    }
  };
}

export function editDescription(
  publish_uid,
  documentation_link,
  description,
  additional_documentation_text
) {
  return async (dispatch) => {
    // wait while data is being updating
    dispatch(editDescriptionLoading());
    // Call the API to update the description
    return PublishApi.updatedDescription(
      publish_uid,
      documentation_link,
      description,
      additional_documentation_text
    )
      .then((res) => {
        //  dispatching a success action with updated data
        dispatch(
          editDescriptionSuccess({
            publish_uid,
            documentation_link,
            description,
            additional_documentation_text,
          })
        );
      })
      .catch((error) => {
        console.error(`error while fetching the data ${error}`);
        // dispatching an error action in case of failure
        dispatch(editDescriptionError());
      });
  };
}

//
export function editBasicInfo(
  publish_uid,
  title,
  attribution,
  price_type,
  publisher,
  subtitle,
  tags,
  subcription_type,
  product_cost,
  monthly_subcription_price,
  yearly_subcription_price
) {
  return (dispatch) => {
    dispatch(updatedBasicInfoLoading());
    return PublishApi.updateBasicInfo(
      publish_uid,
      title,
      attribution,
      price_type,
      publisher,
      subtitle,
      tags,
      subcription_type,
      product_cost,
      monthly_subcription_price,
      yearly_subcription_price
    )
      .then((res) => {
        console.log(res);
        dispatch(
          updatedBasicInfoSuccess({
            publish_uid,
            title,
            attribution,
            price_type,
            publisher,
            subtitle,
            tags,
            subcription_type,
            product_cost,
            monthly_subcription_price,
            yearly_subcription_price,
          })
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(updatedBasicInfoError());
      });
  };
}

// action to fetch the filtered datasets
export function filteredPublishedDatasets(page, title, status) {
  return async (dispatch) => {
    dispatch(datasetListLoading());
    try {
      const response = await PublishApi.getFilteredDatasets(
        page,
        title,
        status
      );
      // console.log(response.data);
      dispatch(datasetListSuccess(response.data));
    } catch (error) {
      console.error("Error fetching filtered datasets:", error);
      dispatch(datasetsListError());
    }
  };
}
