import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "../../styles/editBasicInfoPopupComponent.scss";
import HgButtonComponent from "./hgButtonComponent";
import PillButton from "./pillButton";
import { useDispatch } from "react-redux";
import { editBasicInfo } from "../../actions/publishActions";
import { Button } from "@mui/material";
import MultiSelect from "./MultiSelect";
import AttributionMultiSelect from "./AttributionMultiSelect";

const EditBasicInfoPopupComponent = (props) => {
  const {
    modal,
    toggle,
    setIsProductPricingModalOpen,
    datasetName,
    publisher,
    existingAttributions,
    subtitle,
    existingTags,
    publish_uid,
    price_type,
    subcription_type,
    product_cost,
    monthly_subcription_price,
    yearly_subcription_price,
  } = props;

  const [inputValue, setInputValue] = useState("");
  const [editTitle, setEditTitle] = useState(datasetName);
  const [editPublisher, setEditPublisher] = useState(publisher);
  const [editSubtitle, setEditSubtitle] = useState(subtitle);
  const [selectedKeyword, setSelectedKeyword] = useState({
      selectedKeyword: [],
    });
  const [selectedAttribution, setSelectedAttribution] = useState({selectedAttribution:[]});

  const dispatch = useDispatch();
   useEffect(() => {
    setEditTitle(datasetName);
    setEditPublisher(publisher);
    setEditSubtitle(subtitle);
    setSelectedAttribution({...selectedAttribution, selectedAttribution:existingAttributions ? existingAttributions : []});
      setSelectedKeyword({ ...selectedKeyword, selectedKeyword: existingTags }); // Set selectedKeyword to existingTags
    }, [datasetName, publisher, existingAttributions, subtitle, existingAttributions, existingTags]);

  const handlEditPricing = () => {
    toggle();
    setIsProductPricingModalOpen(true);
  };

  const handleSave = () => {
    dispatch(
      editBasicInfo(
        publish_uid,
        editTitle,
        selectedAttribution.selectedAttribution,
        price_type,
        editPublisher,
        editSubtitle,
        selectedKeyword.selectedKeyword,
        subcription_type,
        product_cost,
        monthly_subcription_price,
        yearly_subcription_price
      )
    );
    toggle();
  };

  return (
    <div className="edit-basic-info-component">
      <Modal
        isOpen={modal}
        toggle={toggle}
        size={"md"}
        className="edit-basic-info-component"
        {...props}
      >
        <ModalHeader>Basic Information</ModalHeader>
        <ModalBody>
          <div className="headings">Title</div>
          <input
            type="text"
            className="input-feilds"
            value={editTitle}
            onChange={(e) => setEditTitle(e.target.value)}
          />
          <div className="headings">Pricing</div>
          <div className="pricing">
            <label className="type">TYPE</label>
            <div className="paid">{price_type}</div>
            <label className="type">PRICE</label>
            <div className="paid">{product_cost}</div>
            <label className="type">BILLING</label>
            <div className="paid">MONTHLY</div>
          </div>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
            }}
            className="edit-pricing"
            onClick={() => handlEditPricing()}
          >
            Edit Pricing
          </Button>
          <div className="headings">Publisher</div>
          <input
            type="text"
            className="input-feilds"
            value={editPublisher}
            onChange={(e) => setEditPublisher(e.target.value)}
          />
          <div className="headings">Attribution</div>
          <div className="custom-tags">
              <AttributionMultiSelect
                selectedAttribution={selectedAttribution.selectedAttribution}
                setSelectedAttribution={setSelectedAttribution}
              />
            </div>
          <div className="headings">Subtitle</div>
          <input
            type="text"
            className="input-feilds"
            value={editSubtitle}
            onChange={(e) => setEditSubtitle(e.target.value)}
          />
          <div className="headings">Tags</div>
          <div className="custom-tags">
            <MultiSelect
              selectedKeyword={selectedKeyword.selectedKeyword}
              setSelectedKeyword={setSelectedKeyword}
             />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outlined"
            sx={{
              textTransform: "none",
              backgroundColor: "none",
              color: "#00639A",
              borderRadius: "25px",
              padding: "8px 24px",
              "&:hover": { backgroundColor: "#f1faff" },
            }}
            onClick={toggle}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              backgroundColor: "#00639A",
              color: "white",
              borderRadius: "25px",
              padding: "8px 24px",
              "&:hover": { backgroundColor: "#347ba1" },
            }}
            onClick={handleSave}
          >
            Save
          </Button>
          {/* <HgButtonComponent
            buttonText={"Save"}
            isPrimary={true}
            onclickHandler={handleSave}
          /> */}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditBasicInfoPopupComponent;
