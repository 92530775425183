// AccountSetting.js
import React, { Component } from "react";
import "../styles/loginComponent.scss";
import "../styles/commonStyle.scss";
import "../styles/form.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as accountActions from "../actions/accountActions";
import { FORM_VALIDATION, STORAGE_KEY } from "../config";
import FormValidationErrorContainer from "./pure/formValidationErrorContainer";
import { ImgLoginBgEclipse, ImgNlg, Logo } from "../images";
import Loader from "./loaderComponent";
import {
  localStorageGetObject,
  localStorageSetItem,
} from "../utils/localStorageUtils";
import HeaderComponent from "./pure/headerComponent";
import { FormUtils } from "../utils/formUtils";
import { changePassword } from "../actions/accountActions";
import { Button } from "@mui/material";

class AccountSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      username: "",
      oldPassword: "",
      password: "",
      confirmPassword: "",
      organisation: "",
      usernameValid: true,
      oldPasswordValid: true,
      passwordValid: true,
      confirmPasswordValid: true,
      organisationValid: true,
      usernameErrorMessage: "Enter your email address",
      oldPasswordErrorMessage: "Enter your current password",
      passwordErrorMessage: "Enter your password",
      confirmPasswordErrorMessage: "Please confirm password",
      organisationErrorMessage: "Please enter your organistation",
      passwordRules: {
        minLength: false,
        hasSymbols: false,
        hasBothCases: false,
        hasNumber: false,
      },
      PasswordVisible: false,
    };
  }

  componentDidMount() {
    const userInfo = localStorageGetObject(STORAGE_KEY.user_info);
    this.setState({
      username: userInfo.email,
      firstName: userInfo.first_name,
      lastName: userInfo.last_name,
      // organizations: userInfo.organizations
      // organisation: userInfo.userprofile.organization_name
    });
  }

  componentWillUnmount() {
    this.props.actions.changePasswordResetState();
  }

  onBlur = (e) => {
    const { name, value } = e.target;
    this.validateField(name, value);
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.validateField(name, value);
  };

  onSubmit = async (e) => {
    e.preventDefault();
    console.log(
      "logcheckk222",
      this.state.password,
      this.state.confirmPassword,
      this.state.oldPassword
    );
    if (this.validateForm()) {
      this.props.actions.changePassword(
        this.state.oldPassword,
        this.state.password,
        this.state.confirmPassword
      );
    }
    console.log("checkactionss");
  };

  validateField = (name, value) => {
    if (name === "confirmPassword") {
      const { fieldValid, errorMessage } = FormUtils.validateFormField(
        name,
        value,
        this.state.password
      );
      this.updateFormState(name, value, fieldValid, errorMessage);
    } else if (name === "password") {
      const { fieldValid, errorMessage, comparedValueValid } =
        FormUtils.validateFormField(name, value, this.state.confirmPassword);
      this.updateFormState(
        name,
        value,
        fieldValid,
        errorMessage,
        comparedValueValid
      );
    } else {
      const { fieldValid, errorMessage } = FormUtils.validateFormField(
        name,
        value
      );
      this.updateFormState(name, value, fieldValid, errorMessage);
    }
  };

  updateFormState = (
    name,
    value,
    fieldValid,
    errorMessage,
    comparedValueValid = true
  ) => {
    const state = {
      [name]: value,
      [name + "Valid"]: fieldValid,
      [name + "ErrorMessage"]: errorMessage,
    };
    if (name === "password") {
      state["confirmPasswordValid"] = comparedValueValid;
    }
    this.setState(state);
  };

  validateForm = () => {
    if (
      this.state.oldPassword.length > 0 &&
      this.state.oldPasswordValid &&
      this.state.password.length > 0 &&
      this.state.passwordValid &&
      this.state.confirmPassword.length > 0 &&
      this.state.confirmPasswordValid
    ) {
      return true;
    } else {
      if (this.state.oldPassword.length == 0) {
        this.setState({
          oldPasswordValid: false,
          oldPasswordErrorMessage: "Enter your current password",
        });
      }

      if (this.state.password.length == 0) {
        this.setState({
          passwordValid: false,
          passwordErrorMessage: "Enter new password",
        });
      }

      if (this.state.confirmPassword.length == 0) {
        this.setState({
          confirmPasswordValid: false,
          confirmPasswordErrorMessage: "Enter confirm password",
        });
      }
    }
  };

  onCancelHandler = () => {
    this.props.actions.changePasswordResetState();
    this.props.history.goBack();
  };

  render() {
    return (
      <div className="login-component manage-account">
        <HeaderComponent />
        <main className="main" style={{ margin: "auto" }}>
          {this.props.isLoading && <Loader />}
          {!this.props.isChangePasswordSuccess ? (
            <>
              <div className="section-heading">Account Settings</div>
              <form
                className="dsg-form"
                onSubmit={this.onSubmit}
                noValidate={false}
              >
                <div className="form-field">
                  <div className="form-field-label">Password</div>
                  <input
                    type="password"
                    id="old-password"
                    name="oldPassword"
                    className="form-control"
                    placeholder="Enter Current Password"
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    value={this.state.oldPassword}
                  />
                  {/* <span className="form-icon password"></span> */}
                  {!this.state.oldPasswordValid && (
                    <FormValidationErrorContainer
                      errMessage={this.state.oldPasswordErrorMessage}
                    />
                  )}
                </div>
                <div className="form-field">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className="form-control"
                    placeholder="Enter New Password"
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    value={this.state.password}
                  />
                  {/* <span className="form-icon password"></span> */}
                  {!this.state.passwordValid && (
                    <FormValidationErrorContainer
                      errMessage={this.state.passwordErrorMessage}
                    />
                  )}
                </div>
                <div className="form-field">
                  <input
                    type="password"
                    id="conf-password"
                    name="confirmPassword"
                    className="form-control"
                    placeholder="Confirm Password"
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    value={this.state.confirmPassword}
                  />
                  {/* <span className="form-icon password"></span> */}
                  {!this.state.confirmPasswordValid && (
                    <FormValidationErrorContainer
                      errMessage={this.state.confirmPasswordErrorMessage}
                    />
                  )}
                </div>
                <div className="manage-actions">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "black",
                      color: "white",
                      "&:hover": { backgroundColor: "#272727" },
                    }}
                    className="submit"
                    type="submit"
                    disabled={this.props.isLoading}
                  >
                    Save Changes
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "black",
                      color: "white",
                      "&:hover": { backgroundColor: "#272727" },
                    }}
                    className="submit"
                    type="button"
                    disabled={this.props.isLoading}
                    onClick={this.onCancelHandler}
                  >
                    Cancel
                  </Button>
                </div>
                {this.props.formError && (
                  <FormValidationErrorContainer
                    errMessage={this.props.formError}
                  />
                )}
                {/* <div className="another-login-option">or</div>
                            <button className="submit google-login" type="button"><img src={ImgGoogle} /> Sign In using Google</button> */}
                {/* <div className="sign-up">
                                Not registered yet?<Link to={'/sign-up'}>Signup</Link>
                            </div> */}
              </form>
            </>
          ) : (
            <>
              <div className="section-heading">
                Password Updated Successfully!
              </div>
              <div className="helper-text">
                <span
                  style={{ color: "#3cfef5", cursor: "pointer" }}
                  onClick={this.onCancelHandler}
                >
                  Click here to go back.
                </span>
              </div>
            </>
          )}
        </main>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.account.isChangePasswordLoading,
    isChangePasswordSuccess: state.account.isChangePasswordSuccess,
    formError: state.account.formError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(accountActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSetting);
