import React, { Component } from "react";
import "../styles/loginComponent.scss";
import "../styles/commonStyle.scss";
import "../styles/form.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sessionActions from "../actions/sessionActions";
import { FORM_VALIDATION, STORAGE_KEY } from "../config";
import FormValidationErrorContainer from "./pure/formValidationErrorContainer";
import {
  ImgGoogle,
  ImgGooglePlus,
  ImgLoginBgEclipse,
  ImgNlg,
  ImgOMTLogo,
  ImgTBBCLogo,
  ImgWelcomeTBBC,
  Logo,
} from "../images";
import Loader from "./loaderComponent";
import { localStorageSetItem } from "../utils/localStorageUtils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button } from "@mui/material";

class LoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      usernameValid: true,
      passwordValid: true,
      usernameErrorMessage: "Enter your email address",
      passwordErrorMessage: "Enter your password",
      popupTitle: null,
      popupMessage: null,
      PasswordVisible: false,
      isRememberMe: true,
      showPassword: false,
    };
  }

  onBlur = (e) => {
    const { name, value } = e.target;
    this.validateField(name, value);
    this.setState({
      [name]: value,
    });
  };

  handleEyeClick = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.validateField(name, value);
    this.setState({
      [name]: value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      localStorageSetItem(STORAGE_KEY.rememberMe, this.state.isRememberMe);
      this.props.actions.login(this.state.username, this.state.password);
    }
  };

  validateField = (fieldName, value) => {
    let usernameValid = this.state.usernameValid;
    let passwordValid = this.state.passwordValid;
    let usernameErrorMessage = this.state.usernameErrorMessage;
    let passwordErrorMessage = this.state.passwordErrorMessage;

    switch (fieldName) {
      case "username":
        usernameValid =
          value.length === 0 || FORM_VALIDATION.username.test(value);
        usernameErrorMessage = "Enter a valid email address";
        break;
      case "password":
        passwordValid = true;
        passwordErrorMessage = "";
        break;
      default:
        break;
    }

    this.setState({
      usernameValid,
      passwordValid,
      usernameErrorMessage,
      passwordErrorMessage,
    });
  };

  validateForm = () => {
    if (
      this.state.username.length > 0 &&
      this.state.usernameValid &&
      this.state.password.length > 0 &&
      this.state.passwordValid
    ) {
      return true;
    } else {
      if (this.state.username.length == 0) {
        this.setState({
          usernameValid: false,
          usernameErrorMessage: "Enter your email address",
        });
      }

      if (this.state.password.length == 0) {
        this.setState({
          passwordValid: false,
          passwordErrorMessage: "Enter your password",
        });
      }
    }
  };

  rememberMeHandler = (e) => {
    this.setState({ isRememberMe: !this.state.isRememberMe });
  };

  render() {
    return (
      <div className="login-component">
        {/* <header className="header">
                    <Link to={'/'}><img src={Logo} /></Link>
                </header> */}
        <main className={this.props.loginSuccess ? "main slide-right" : "main"}>
          {/* {this.props.isLoading && <Loader />} */}
          <div style={{ marginBottom: "24px" }}>
            <img src={ImgTBBCLogo} />
          </div>
          <div className="section-heading">ACCOUNT LOG IN</div>
          {/* <div className="helper-text">Welcome back!</div> */}
          <form
            className="dsg-form"
            onSubmit={this.onSubmit}
            noValidate={false}
          >
            <div className="form-field">
              <input
                type="text"
                id="username"
                name="username"
                data-testid="email-input"
                className="form-control"
                placeholder="email address"
                onBlur={this.onBlur}
                onChange={this.onChange}
                value={this.state.username}
              />
              {/* <span className="form-icon email"></span> */}
              {!this.state.usernameValid && (
                <FormValidationErrorContainer
                  errMessage={this.state.usernameErrorMessage}
                />
              )}
            </div>
            <div className="form-field">
              <input
                type={this.state.showPassword ? "text" : "password"}
                id="password"
                name="password"
                data-testid="password-input"
                className="form-control"
                placeholder="password"
                onBlur={this.onBlur}
                onChange={this.onChange}
                value={this.state.password}
              />
              {/* <span className="form-icon password"></span> */}

              <span
                aria-label="visibility"
                role="button"
                className="password-toggle-icon"
                onClick={this.handleEyeClick}
              >
                {this.state.showPassword ? (
                  <VisibilityOffIcon />
                ) : (
                  <VisibilityIcon />
                )}
              </span>

              {!this.state.passwordValid && (
                <FormValidationErrorContainer
                  errMessage={this.state.passwordErrorMessage}
                />
              )}
            </div>
            <div className="form-field fgt-pwd">
              <input
                type="checkbox"
                id="remember-me"
                name="remember-me"
                hidden={true}
                defaultChecked={this.state.isRememberMe}
                value={this.state.isRememberMe}
                onChange={this.rememberMeHandler}
              />
              <label htmlFor="remember-me" className="dsg-checkbox">
                Remember me
              </label>
              <div className="forgot-pwd">
                <Link to={"reset-password"}> Forgot Password</Link>
              </div>
            </div>
            <Button
              variant="contained"
              sx={{ "&:hover": { backgroundColor: "#303030" } }}
              data-testid="login-button"
              className="submit"
              type="submit"
              disabled={this.props.isLoading}
            >
              Log In
            </Button>
            {this.props.formError && (
              <FormValidationErrorContainer errMessage={this.props.formError} />
            )}
            {/* <div className="another-login-option">or</div>
                        <button className="submit google-login" type="button"><img src={ImgGoogle} /> Sign In using Google</button> */}
            <div className="sign-up">
              <Link to={"/sign-up"}>
                <Button
                  variant="outlined"
                  sx={{
                    "&:hover": {
                      border: "1px solid #303030",
                      backgroundColor: "#eeeeee",
                    },
                  }}
                  className="button"
                  type="button"
                  disabled={this.props.isLoading}
                >
                  Sign Up
                </Button>
              </Link>
            </div>

            {/* {this.props.isLoading && <Loader customClass={'sm'} />} */}
          </form>
        </main>
        <img src={ImgWelcomeTBBC} className="welcome-tbbc" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    formError: state.session.loginErrorMessage,
    isLoading: state.session.isLoading,
    loginSuccess: state.session.loginSuccess,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}
export { LoginComponent };
export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
