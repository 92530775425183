import { API_URL } from "../config";
import axiosInstance from "./axiosConfig";

export default class PublishApi {
  /**
   * @method GET
   * @description fetch the dataset
   * @returns PROMISE
   */
  static getDatasetToBePublish(search_keywords, page) {
    // hit the API endpoint and return a promise
    const data = axiosInstance.get(
      `${API_URL}/auth/v1/publish/user-dataset/list/`,
      {
        params: { search_keywords, page },
      }
    );
    return data;
  }

  /**
   * @method POST
   * @description upload the selected dataset to publish
   * @returns PROMISE
   */
  static uploadDatasetToPublish(requestObj, uid) {
    const data = axiosInstance.post(
      `${API_URL}/auth/v1/update-version-status/`,
      { ...requestObj },
      { params: { version_uid: uid } }
    );

    return data;
  }

  // API for listing the datasets (main screen)
  static getDatasetsList(page) {
    const params = {
      page,
    };
    return axiosInstance.get(`${API_URL}/auth/v1/publishing-manager/list/`, {
      params: params,
    });
  }

  // API for fetching the details of a specific published dataset
  static getPublishedDatasetDetails(publish_id) {
    const params = {
      publish_id,
    };
    return axiosInstance.get(`${API_URL}/auth/v1/get-publish-dataset/detail/`, {
      params: params,
    });
  }

  /**
   * @method PUT
   * @description edit the description of the dataset
   * @returns PROMISE
   */

  // API to update the description
  static updatedDescription(
    publish_uid,
    documentation_link,
    description,
    additional_documentation_text
  ) {
    return axiosInstance.put(`${API_URL}/auth/v1/update-publish/description/`, {
      publish_uid: publish_uid,
      documentation_link: documentation_link,
      description: description,
      additional_documentation_text: additional_documentation_text,
    });
  }

  static updateBasicInfo(
    publish_uid,
    title,
    attribution,
    price_type,
    publisher,
    subtitle,
    tags,
    subcription_type,
    product_cost,
    monthly_subcription_price,
    yearly_subcription_price
  ) {
    return axiosInstance.put(`${API_URL}/auth/v1/update-publish/basic-info/`, {
      publish_uid: publish_uid,
      title: title,
      attribution: attribution,
      price_type: price_type,
      publisher: publisher,
      subtitle: subtitle,
      tags: tags,
      subscription_type: subcription_type,
      product_cost: product_cost,
      monthly_subcription_price: monthly_subcription_price,
      yearly_subcription_price: yearly_subcription_price,
    });
  }

  // API for fetching the filtered datasets
  static getFilteredDatasets(page, title, status) {
    const data = axiosInstance.get(
      `${API_URL}/auth/v1/publishing-manager/filter/`,
      {
        params: {
          page,
          title,
          status,
        },
      }
    );
    return data;
  }
}
