import React, { useState, useEffect } from 'react'
import '../../styles/manageAccess.scss'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { ImgSearch } from '../../images';
import { IoIosCloseCircleOutline } from "react-icons/io";
import Switch from '@mui/material/Switch';
import EditAccessPopupComponent from './editAccessPopupComponent';
import HgButtonComponent from './hgButtonComponent';
import SearchBarComponent from './searchBarComponent';
import {removeAccessType} from '../../actions/datasetActions';
import { useDispatch } from 'react-redux';

const ManageAccessPopupComponent = (props) => {
  const { toggle, modal, sharedUsers, rootDataset } = props
  const [editAccess, setEditAccess] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [searchText, setSearchText] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(sharedUsers);
  const dispatch = useDispatch();

  const handleEditAccess = (user) => {
    setSelectedUser(user);
    toggle(); 
    setEditAccess(true);
  };

  useEffect(() => {
    filterUsers(searchText);
  }, [searchText, sharedUsers]);

  const handleCancelEditAccess = () => {
    setEditAccess(false); 
    toggle(); 
  };

  const handleRemoveAccess = (user) => {
    setSelectedUser(user);
    dispatch(removeAccessType(user.email, rootDataset.uid));
    const sharedDatasetsAfterRemove = sharedUsers.filter((item) => item.email !== user.email);
    setFilteredUsers(sharedDatasetsAfterRemove);
  }

  const filterUsers = (searchText) => {
    const keywords = searchText.toLowerCase().split(",");
    const filtered = sharedUsers.filter((user) =>
      keywords.some((keyword) =>
        `${user.first_name} ${user.last_name}`.toLowerCase().includes(keyword)
      )
    );
    setFilteredUsers(filtered);
  };

  return (
    <div className='manage-access-component'>
    <Modal isOpen={modal} toggle={toggle} size={"md"} className='manage-access-component' {...props}>
      <ModalHeader>Manage Access</ModalHeader>
      <ModalBody>
        <div className='sub-heading'>Following datasets are processing in the background. Please do not close or exit your browser window to ensure the upload completes successfully.</div>
        <Button className='upload-btn'>Upload New Version</Button>
        <div className='search-container'>
        <SearchBarComponent setSearchText={setSearchText}/>
        </div>
        <div className='user-list'>
          {filteredUsers.map((item)=> (
            <div key={item.uid} className='user-row'>
              <span className="user-name">{`${item.first_name} ${item.last_name}`}</span>
              <span className='user-role'>{item.access_type.charAt(0).toUpperCase() + item.access_type.slice(1).toLowerCase()}</span>
              <span className="edit-access" onClick={() => handleEditAccess(item)}>Edit Access</span>
              <span className="remove-user" onClick={() => handleRemoveAccess(item)} aria-label="Close" role="button"><IoIosCloseCircleOutline size={24} /></span>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className='footer'>
          <Switch/>
          <label>Apply changes to all versions</label>
          <HgButtonComponent buttonText={"Cancel"} isPrimary={false} onclickHandler={toggle} />
          <HgButtonComponent buttonText={"Save"} isPrimary={true} onclickHandler={toggle}/>
        </div>
      </ModalFooter>
    </Modal>
    <EditAccessPopupComponent
      toggle={handleCancelEditAccess}
      modal={editAccess}
      user={selectedUser} 
      rootDataset={rootDataset}
    />
    </div>
  )
}

export default ManageAccessPopupComponent