import React, { Component } from "react";
import "../styles/loginComponent.scss";
import "../styles/commonStyle.scss";
import "../styles/form.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as accountActions from "../actions/accountActions";
import { FORM_VALIDATION, STORAGE_KEY } from "../config";
import FormValidationErrorContainer from "./pure/formValidationErrorContainer";
import {
  ImgGoogle,
  ImgGooglePlus,
  ImgLoginBgEclipse,
  ImgNlg,
  ImgOMTLogo,
  ImgTBBCLogo,
  ImgWelcomeTBBC,
  Logo,
} from "../images";
import Loader from "./loaderComponent";
import { localStorageSetItem } from "../utils/localStorageUtils";
import { FormUtils } from "../utils/formUtils";
import { Button } from "@mui/material";

class ResetPasswordComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      usernameValid: true,
      usernameErrorMessage: "Enter your email address",
    };
  }

  componentWillUnmount() {
    this.props.actions.accountStateReset();
  }

  onBlur = (e) => {
    const { name, value } = e.target;
    this.validateField(name, value);
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.validateField(name, value);
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.props.actions.getResetPasswordLink(this.state.username);
    }
  };

  validateField = (name, value) => {
    const { fieldValid, errorMessage } = FormUtils.validateFormField(
      name,
      value
    );
    this.updateFormState(name, value, fieldValid, errorMessage);
  };

  updateFormState = (
    name,
    value,
    fieldValid,
    errorMessage,
    comparedValueValid = true
  ) => {
    const state = {
      [name]: value,
      [name + "Valid"]: fieldValid,
      [name + "ErrorMessage"]: errorMessage,
    };
    this.setState(state);
  };

  validateForm = () => {
    if (this.state.username.length > 0 && this.state.usernameValid) {
      return true;
    } else {
      if (this.state.username.length == 0) {
        this.setState({
          usernameValid: false,
          usernameErrorMessage: "Enter your email address",
        });
      }
    }
  };

  sendResendPasswordLinkHandler = () => {
    this.props.actions.getResetPasswordLink(this.state.username);
  };

  render() {
    return (
      <div className="login-component">
        {/* <header className="header">
                    <Link to={'/'}><img src={Logo} /></Link>
                </header> */}
        <main className="main">
          {this.props.isLoading && <Loader />}
          {!this.props.isResetPasswordSuccess ? (
            <>
              <div style={{ marginBottom: "24px" }}>
                <img src={ImgTBBCLogo} />
              </div>
              <div className="section-heading">ACCOUNT RECOVERY</div>
              <div className="helper-text">
                Enter your email that you using with Big Blue Cloud account
              </div>
              <form
                className="dsg-form"
                onSubmit={this.onSubmit}
                noValidate={false}
              >
                <div className="form-field">
                  <input
                    type="text"
                    id="username"
                    name="username"
                    className="form-control"
                    placeholder="email"
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    value={this.state.username}
                  />
                  {/* <span className="form-icon email" ></span> */}
                  {!this.state.usernameValid && (
                    <FormValidationErrorContainer
                      errMessage={this.state.usernameErrorMessage}
                    />
                  )}
                </div>
                <Button
                  variant="contained"
                  sx={{ "&:hover": { backgroundColor: "#303030" } }}
                  className="submit"
                  type="submit"
                  disabled={this.props.isLoading}
                >
                  Next
                </Button>
                {this.props.formError && (
                  <FormValidationErrorContainer
                    errMessage={this.props.formError}
                  />
                )}
              </form>
            </>
          ) : (
            <>
              <div className="section-heading">Email Sent</div>
              <div className="helper-text">
                Please check inbox for reset password link.
              </div>
              <div className="helper-text">
                Can't find it?{" "}
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={this.sendResendPasswordLinkHandler}
                >
                  Click here to resend the link.
                </span>
              </div>
            </>
          )}
        </main>
        <img src={ImgWelcomeTBBC} className="welcome-tbbc" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.account.isResetPasswordLoading,
    isResetPasswordSuccess: state.account.isResetPasswordSuccess,
    formError: state.account.formError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(accountActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordComponent);
