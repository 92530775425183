import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import "../../styles/searchDatasets.scss";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import HgButtonComponent from "./hgButtonComponent";
import HgDropdownComponent from "./hgDropdownComponent";
import { IoIosSearch } from "react-icons/io";
import {
  LICENSE_TYPE,
  OPEN_SOURCE_LICENSE_LIST,
  PROPRIETARY_LICENSE_LIST,
  PUBLISH_DATASET_STATUS,
} from "../../config";
import {
  publishDataset,
  resetPublishDataset,
  uploadDataset,
} from "../../actions/publishActions";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { Button, Typography } from "@mui/material";
import { v4 as uuid } from "uuid";
import Loader from "../loaderComponent";

/**
 * @constant MODAL_VALUE
 * @description value for different modal to render it conditionally
 */
const MODAL_VALUE = {
  SEARCH: "SEARCH_MODAL",
  LICENSE: "MANAGE_LICENSE_MODAL",
};

const cardSubHeadingStyle = { textAlign: "center", fontSize: "13px" };

function SearchDatasets({
  searchModal,
  setSearchModal,
  publishDataset,
  publishData,
  uploadDataset,
}) {
  const [showDescription, setShowDescription] = useState([]);
  // by default it will popup the Search Modal
  const [modalValue, setModalValue] = useState(MODAL_VALUE.SEARCH);
  // defalut value for license is Open source
  const [selectedLicense, setSelectedLicense] = useState(
    LICENSE_TYPE.OPEN_SOURCE
  );
  const [selectedDatasetId, setSelectedDatasetId] = useState(null);
  const [linceseType, setLinceseType] = useState(null);
  const [updatedDataset, setUpdatedDataset] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [dropdownDefaultValue, setDropdownDefaultValue] =
    useState("No License");
  const [pageNumber, setPageNumber] = useState(1);
  // getting the Table element
  const datasetTable = useRef(null);
  const dispatch = useDispatch();

  // toggle functionality for description
  const handleDescription = (id) => {
    setShowDescription((prevState) => {
      return prevState.includes(id)
        ? prevState.filter((item) => item !== id)
        : [...prevState, id];
    });
  };

  // get the selecting dataset to publish
  const handleSelection = (selectedDataset) => {
    // check first in updatedDataset state if not available the send the deafult first value;
    const filterDataset = updatedDataset.filter(
      (item) => item.uid === selectedDataset.uid
    );
    if (filterDataset.length) {
      setSelectedDatasetId(filterDataset[0].latest_version[0].version_uid);
    } else {
      setSelectedDatasetId(selectedDataset.latest_version[0].version_uid);
    }
  };

  // set the license tpye
  const handleLicense = (item) => {
    setLinceseType(item.value);
  };

  // dispatch the action with payload to publish dataset
  const handelPublishDataset = () => {
    uploadDataset(selectedDatasetId, PUBLISH_DATASET_STATUS.DRAFT, linceseType);
    handleCancle();
  };

  // store the updatedDataset with chage version
  const handleUpdateVersion = (datasetObj, selectedVersionId) => {
    const filterVersionDataset = datasetObj.latest_version.filter(
      (item) => item.version_uid === selectedVersionId
    );
    setUpdatedDataset((prevState) => {
      if (prevState.length) {
        const existingDatasetIndex = prevState.findIndex(
          (item) => item.uid === datasetObj.uid
        );
        if (existingDatasetIndex !== -1) {
          prevState[existingDatasetIndex] = {
            ...prevState[existingDatasetIndex],
            latest_version: filterVersionDataset,
          };
          return prevState;
        }
      }
      return [
        ...prevState,
        { ...datasetObj, latest_version: filterVersionDataset },
      ];
    });
  };

  // resetting the all values
  const handleCancle = () => {
    setLinceseType(null);
    setSelectedDatasetId(null);
    setModalValue(MODAL_VALUE.SEARCH);
    setSearchModal(false);
    setInputValue("");
  };

  useEffect(() => {
    // de-boucing the API call
    const timer = setTimeout(() => {
      if (searchModal) {
        publishDataset(inputValue, pageNumber);
      }
    }, 250);
    return () => {
      clearTimeout(timer);
    };
  }, [searchModal, inputValue, pageNumber]);

  useEffect(() => {
    // change the default value of license acc to license type
    if (selectedLicense === LICENSE_TYPE.OPEN_SOURCE) {
      setDropdownDefaultValue("No license");
    } else {
      setDropdownDefaultValue(PROPRIETARY_LICENSE_LIST[0].label);
    }
  }, [selectedLicense]);

  // reseting the toBePublishDataset on closing the modal and changing the search value
  useEffect(() => {
    dispatch(resetPublishDataset());
    setPageNumber(1);
  }, [searchModal, inputValue]);
  const observer = useRef();
  // getting last element and setting up observer on that
  const lastElement = useCallback(
    (node) => {
      if (publishData.isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          publishData.toBePublishDataset.has_next
        ) {
          setPageNumber((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [publishData.isLoading, publishData.toBePublishDataset.has_next]
  );
  return (
    <div>
      <Modal
        className="search-modal"
        isOpen={searchModal}
        size="lg"
        centered={true}
      >
        <ModalHeader>
          {/* changing the modal header according to the MODAL_VALUE */}
          {modalValue === MODAL_VALUE.SEARCH ? (
            <>
              <h3>Select Datasets</h3>
              <p>Basic instructions for attaching Data Product to Published</p>
            </>
          ) : (
            <>
              <h3>Manage License</h3>
              <div className="license-header">
                <span>Selected Dataset</span>
                <span style={{ fontSize: "16px" }}>Ocean Marine</span>
              </div>
            </>
          )}
        </ModalHeader>
        <ModalBody>
          {/* changing the modal body according to the MODAL_VALUE */}
          {modalValue === MODAL_VALUE.SEARCH ? (
            <div className="search-modal-body">
              <p>Choose a Dataset</p>
              <div className="search-input">
                <input
                  type="text"
                  placeholder="Search Dataset"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <IoIosSearch
                  className="search-icon"
                  size={22}
                  color="#72777F"
                />
              </div>
              <div className="dataset-table" ref={datasetTable}>
                <table>
                  <thead>
                    <tr>
                      <th>Dataset</th>
                      <th>version</th>
                      <th>Modified</th>
                      <th>Type</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* waiting while data is being fetched and creating the td skeleton  */}
                    {publishData.isLoading &&
                    !Object.keys(publishData?.toBePublishDataset).length ? (
                      [1, 2, 3, 4, 5].map((item) => {
                        return (
                          <tr key={uuid()} style={{ border: "none" }}>
                            <td colSpan="5" style={{ padding: "0px" }}>
                              <Box sx={{ width: "700px" }}>
                                <Typography variant="h3">
                                  <Skeleton animation="wave" />
                                </Typography>
                              </Box>
                            </td>
                          </tr>
                        );
                      })
                    ) : Object.keys(publishData?.toBePublishDataset).length ? (
                      publishData?.toBePublishDataset?.results.map(
                        (item, index) => {
                          if (
                            publishData?.toBePublishDataset?.results.length ===
                            index + 1
                          ) {
                            return (
                              <Fragment key={item.uid}>
                                <tr
                                  ref={lastElement}
                                  style={{
                                    borderBottom: `${
                                      showDescription.includes(item.uid)
                                        ? "none"
                                        : "1px solid #72777f"
                                    }`,
                                  }}
                                >
                                  <td>
                                    <input
                                      type="radio"
                                      onChange={() => handleSelection(item)}
                                      name="dataset-name"
                                      checked={item.latest_version.some(
                                        (version) =>
                                          version.version_uid ===
                                          selectedDatasetId
                                      )}
                                    />
                                    {item.name}
                                  </td>
                                  <td>
                                    <select
                                      onChange={(e) =>
                                        handleUpdateVersion(
                                          item,
                                          e.target.value
                                        )
                                      }
                                      style={{
                                        border: "none",
                                        outline: "none",
                                      }}
                                    >
                                      {item.latest_version.map((versionObj) => (
                                        <option
                                          key={versionObj.version_uid}
                                          value={versionObj.version_uid}
                                        >
                                          {`Version ${versionObj.version}`}
                                        </option>
                                      ))}
                                    </select>
                                  </td>
                                  {/* convert the date into MM/DD/YY */}
                                  <td>
                                    {new Date(
                                      item.last_modified_date
                                    ).toLocaleDateString("en-US")}
                                  </td>
                                  <td>{item.dataset_format}</td>
                                  <td
                                    onClick={() => handleDescription(item.uid)}
                                  >
                                    {showDescription.includes(item.uid)
                                      ? "Hide"
                                      : "View"}
                                  </td>
                                </tr>
                                {showDescription.includes(item.uid) && (
                                  <tr>
                                    <td colSpan="5">
                                      <div className="dataset-description">
                                        <p>Description :</p>
                                        {item.description}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </Fragment>
                            );
                          } else {
                            return (
                              <Fragment key={item.uid}>
                                <tr
                                  style={{
                                    borderBottom: `${
                                      showDescription.includes(item.uid)
                                        ? "none"
                                        : "1px solid #72777f"
                                    }`,
                                  }}
                                >
                                  <td>
                                    <input
                                      type="radio"
                                      onChange={() => handleSelection(item)}
                                      name="dataset-name"
                                      checked={item.latest_version.some(
                                        (version) =>
                                          version.version_uid ===
                                          selectedDatasetId
                                      )}
                                    />
                                    {item.name}
                                  </td>
                                  <td>
                                    <select
                                      onChange={(e) =>
                                        handleUpdateVersion(
                                          item,
                                          e.target.value
                                        )
                                      }
                                      style={{
                                        border: "none",
                                        outline: "none",
                                      }}
                                    >
                                      {item.latest_version.map((versionObj) => (
                                        <option
                                          key={versionObj.version_uid}
                                          value={versionObj.version_uid}
                                        >
                                          {`Version ${versionObj.version}`}
                                        </option>
                                      ))}
                                    </select>
                                  </td>
                                  {/* convert the date into MM/DD/YY */}
                                  <td>
                                    {new Date(
                                      item.last_modified_date
                                    ).toLocaleDateString("en-US")}
                                  </td>
                                  <td>{item.dataset_format}</td>
                                  <td
                                    onClick={() => handleDescription(item.uid)}
                                  >
                                    {showDescription.includes(item.uid)
                                      ? "Hide"
                                      : "View"}
                                  </td>
                                </tr>
                                {showDescription.includes(item.uid) && (
                                  <tr>
                                    <td colSpan="5">
                                      <div className="dataset-description">
                                        <p>Description :</p>
                                        {item.description}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </Fragment>
                            );
                          }
                        }
                      )
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
                {Object.keys(publishData?.toBePublishDataset).length &&
                publishData.isLoading ? (
                  <div className="loader-container">
                    <Loader />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <div className="license-modal-body">
              <div className="modal-body-heading">
                <span>DATASET LINCESE</span>
                <p>Choose the type of license you will use</p>
              </div>
              <div className="license-card-container">
                <div
                  className="license-card"
                  style={{
                    border: `${
                      selectedLicense === LICENSE_TYPE.OPEN_SOURCE
                        ? "2px solid #82A6D9"
                        : "none"
                    }`,
                  }}
                  onClick={() => setSelectedLicense(LICENSE_TYPE.OPEN_SOURCE)}
                >
                  <p style={{ fontWeight: "600" }}>Open Source License</p>
                  <p style={cardSubHeadingStyle}>
                    Choose from available Open Source Licenses
                  </p>
                </div>
                {/* selected license card shows the border */}
                <div
                  className="license-card"
                  style={{
                    border: `${
                      selectedLicense === LICENSE_TYPE.PROPRIETARY
                        ? "2px solid #82A6D9"
                        : "none"
                    }`,
                  }}
                  onClick={() => setSelectedLicense(LICENSE_TYPE.PROPRIETARY)}
                >
                  <p style={{ fontWeight: "600" }}>Proprietary License</p>
                  <p style={cardSubHeadingStyle}>
                    I have a Proprietary License to Upload
                  </p>
                </div>
                <div
                  className="license-card"
                  style={{
                    cursor: "not-allowed",
                    opacity: "0.6",
                  }}
                >
                  <p>No License</p>
                  <p style={cardSubHeadingStyle}>
                    Internal Use Only. License required to publish
                  </p>
                </div>
              </div>
              <div className="license-dropdown">
                <div style={{ marginBottom: "17px" }}>
                  Which license to use{" "}
                </div>
                <div className="license-hgdropdown">
                  <div className="data-license">Data License</div>
                  {selectedLicense === LICENSE_TYPE.OPEN_SOURCE && (
                    <HgDropdownComponent
                      placeholder={"Open Source"}
                      listItems={OPEN_SOURCE_LICENSE_LIST.slice(1)}
                      defaultValue={dropdownDefaultValue}
                      enableSearch={false}
                      onSelectHandler={(item, listIdentifier) =>
                        handleLicense(item)
                      }
                    />
                  )}
                  {selectedLicense === LICENSE_TYPE.PROPRIETARY && (
                    <HgDropdownComponent
                      placeholder={"Proprietary License"}
                      listItems={PROPRIETARY_LICENSE_LIST}
                      defaultValue={dropdownDefaultValue}
                      enableSearch={false}
                      onSelectHandler={(item, listIdentifier) =>
                        handleLicense(item)
                      }
                    />
                  )}
                </div>
                <button disabled={true} className="btn-view-license">
                  View License File
                </button>
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            variant="text"
            sx={{
              textTransform: "none",
              borderRadius: "20px",
              padding: "10px 20px",
            }}
            onClick={handleCancle}
          >
            Cancel
          </Button>
          {/* changing the button according to MODAL_VALUe */}
          <div className="btn-sumbit">
            {modalValue === MODAL_VALUE.LICENSE && (
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#347ba1" },
                }}
                className="btn-back"
                onClick={() => setModalValue(MODAL_VALUE.SEARCH)}
              >
                Back
              </Button>
            )}
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                "&:hover": { backgroundColor: "#347ba1" },
              }}
              disabled={
                modalValue === MODAL_VALUE.SEARCH
                  ? !selectedDatasetId
                  : !linceseType
              }
              onClick={() =>
                modalValue === MODAL_VALUE.SEARCH
                  ? setModalValue(MODAL_VALUE.LICENSE)
                  : handelPublishDataset()
              }
            >
              {modalValue === MODAL_VALUE.SEARCH ? "Next" : "Done"}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

// mapping publish data to props
const mapStateToProps = (state) => {
  return {
    publishData: state.publish,
  };
};

// mapping publish actions to props
const mapDispatchToProps = (dispatch) => {
  return {
    publishDataset: bindActionCreators(publishDataset, dispatch),
    uploadDataset: bindActionCreators(uploadDataset, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchDatasets);
