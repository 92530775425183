import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { ImgTBBCLogo } from "../images";
import "../styles/categoryViewComponent.scss";
import SearchBarComponent from "./pure/searchBarComponent";
import { GoChevronRight, GoPlus } from "react-icons/go";
import GridViewDatasetComponent from "./pure/gridViewDatasetComponent";
import ListViewDatasetComponent from "./pure/listViewDatasetComponent";
import SelectComponent from "./pure/selectComponent";
import { BsGrid } from "react-icons/bs";
import { HiOutlineViewList } from "react-icons/hi";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getOceanDataset,
  getOceanFilterDataset,
  setOceanDataFilter,
} from "../actions/datasetActions";
import Loader from "./loaderComponent";
import SidebarComponent from "./pure/sidebarComponent";
import { SIDEBAR_TABS } from "../config";
import HeaderComponent from "./pure/headerComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import PillButton from "./pure/pillButton";
import { Button } from "@mui/material";

export const categoryDetails = {
  datasets: {
    title: "Datasets",
    description:
      "This includes numerical data collected through various instruments, such as sensors on buoys, ARGO floats, or satellite-based measurement systems. It typically involves physical parameters like temperature, salinity, pH, and ocean currents, used for climate monitoring, oceanographic studies, and marine research.",
    background:
      "radial-gradient(ellipse 68.47% 162.09% at 50% 50%, #DFF9FF 0%, #ADCBEC 100%)",
  },
  charts: {
    title: "Charts",
    description:
      "This includes numerical data collected through various instruments, such as sensors on buoys, ARGO floats, or satellite-based measurement systems. It typically involves physical parameters like temperature, salinity, pH, and ocean currents, used for climate monitoring, oceanographic studies, and marine research.",
    background:
      "radial-gradient(91.86% 222.6% at 18.63% 50%, #D1D1D1 0%, #A3A7AC 100%)",
  },
  dashboards: {
    title: "Dashboards",
    description:
      "This includes numerical data collected through various instruments, such as sensors on buoys, ARGO floats, or satellite-based measurement systems. It typically involves physical parameters like temperature, salinity, pH, and ocean currents, used for climate monitoring, oceanographic studies, and marine research.",
    background:
      "radial-gradient(91.86% 222.6% at 18.63% 50%, #D9D9D9 0%, #ADCBEC 100%)",
  },
  models: {
    title: "Models",
    description:
      "This includes numerical data collected through various instruments, such as sensors on buoys, ARGO floats, or satellite-based measurement systems. It typically involves physical parameters like temperature, salinity, pH, and ocean currents, used for climate monitoring, oceanographic studies, and marine research.",
    background:
      "radial-gradient(62.69% 124.06% at 45.28% 100%, #D3DAEA 0%, #9FBEE1 100%)",
  },
  pipelines: {
    title: "Pipelines",
    description:
      "This includes numerical data collected through various instruments, such as sensors on buoys, ARGO floats, or satellite-based measurement systems. It typically involves physical parameters like temperature, salinity, pH, and ocean currents, used for climate monitoring, oceanographic studies, and marine research.",
    background:
      "radial-gradient(68.19% 157.3% at 50% 88.44%, #B0D1D3 0%, #8EC5C9 100%)",
  },
};

const CategoryViewComponent = () => {
  const [isListView, setIsListView] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filterCount, setfilterCount] = useState(0);
  const { id } = useParams();
  const history = useHistory();

  const category = categoryDetails[id];

  const dispatch = useDispatch();

  const { oceanDatasets, isDatasetsListLoading, filterObj } = useSelector(
    (state) => ({
      oceanDatasets: state.dataset.oceanDatasets,
      isDatasetsListLoading: state.dataset.isDatasetsListLoading,
      filterObj: state.dataset.oceanDataFilter,
    })
  );

  useEffect(() => {
    if (!Object.keys(filterObj).length) {
      dispatch(getOceanDataset());
      setfilterCount(0);
      return;
    }

    const paramObj = {};
    let count = 0;
    Object.keys(filterObj).forEach((filterKey) => {
      if (filterObj[filterKey].length > 0) {
        paramObj[filterKey] = filterObj[filterKey].join(",");
        count += filterObj[filterKey].length;
      }
    });

    setfilterCount(count);
    dispatch(getOceanFilterDataset(paramObj));
  }, [filterObj]);

  const handleSearch = (searchText) => {
    const updatedFilterObj = {
      ...filterObj,
      ...(searchText
        ? { search_keywords: [searchText] }
        : { search_keywords: [] }),
    };

    dispatch(setOceanDataFilter(updatedFilterObj));
  };

  const handleAllDatasets = () => {
    history.push("/view-all-datasets");
  };

  const handleRemoveFilter = (filter, lable) => {
    const filterValue = filterObj[filter].filter((value) => value !== lable);
    dispatch(setOceanDataFilter({ ...filterObj, [filter]: [...filterValue] }));
  };

  // console.log(oceanDatasets);
  return (
    <div className="category-view-component">
      <HeaderComponent />
      <SidebarComponent activeTab={SIDEBAR_TABS.oceanDatasets} />
      <div className="main">
        <div className="ocean-data">Ocean of Data/{category.title}</div>
        <div className="category-view">
          <div className="category-details">
            <div className="data-category">Data Category</div>
            <div className="category-title">{category.title}</div>
            <div className="category-description">{category.description}</div>
          </div>
          <div
            className="category-img"
            style={{ background: category.background }}
          ></div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              marginTop: "30px",
            }}
          >
            <Button
              sx={{
                color: "#72777f",
                marginRight: "20px",
                borderRadius: "25px",
                border: "1px solid #72777f",
                "&:hover": { backgroundColor: "#d9d9d9", border: "none" },
              }}
              className="filter-btn"
              onClick={() => setIsFilter(true)}
            >
              <GoPlus /> Filters {filterCount > 0 ? `(${filterCount})` : ""}
            </Button>
            {isFilter && (
              <>
                <div className="backdrop">
                  <div className="filter-popup">
                    <SelectComponent setIsFilter={setIsFilter} />
                  </div>
                </div>
              </>
            )}

            <SearchBarComponent
              searchText={filterObj.search_keywords?.join(",") || ""}
              setSearchText={handleSearch}
            />
          </div>
          <div style={{ display: "flex", marginBottom: "18px" }}>
            <BsGrid
              onClick={() => setIsListView(false)}
              size={25}
              style={{
                color: `${!isListView ? "#004A75" : "#D5E4F6"}`,
                cursor: "pointer",
                marginRight: "15px",
              }}
            />
            <HiOutlineViewList
              style={{
                color: `${isListView ? "#004A75" : "#D5E4F6"}`,
                cursor: "pointer",
              }}
              onClick={() => setIsListView(true)}
              size={25}
            />
          </div>
        </div>
        <div className="active_filter">
          {Object.keys(filterObj)
            .filter((filter) => filter !== "search_keywords")
            .map((filter) => {
              return filterObj[filter].map((label, index) => (
                <PillButton
                  keyword={label}
                  removeKeyword={() => handleRemoveFilter(filter, label)}
                />
              ));
            })}
        </div>
        <div className="datasets">
          <div className="heading">Popular</div>
          <div className="view-all">
            <div className="view-all-text">View All</div>
            <div className="next-icon">
              <GoChevronRight />
            </div>
          </div>
        </div>
        {!isListView ? (
          <div className="popular-datasets-grid">
            {oceanDatasets.results
              .slice(oceanDatasets.results.length - 3)
              .map((item) => (
                <Link
                  key={item.uid}
                  to={`/product-listing/${item.uid}`}
                  style={{ textDecoration: "none" }}
                >
                  <GridViewDatasetComponent
                    title={item.title}
                    description={item.description}
                    payment={item.price_type}
                    license={item.license}
                    dataset_format={item.dataset_format}
                    data_type={item.data_type}
                  />
                </Link>
              ))}
          </div>
        ) : (
          <div className="popular-datasets-list">
            {oceanDatasets.results
              .slice(oceanDatasets.results.length - 3)
              .map((item) => (
                <Link
                  key={item.uid}
                  to={`/product-listing/${item.uid}`}
                  style={{ textDecoration: "none" }}
                >
                  <ListViewDatasetComponent
                    title={item.title}
                    description={item.description}
                    payment={item.price_type}
                    license={item.license}
                    data_type={item.data_type}
                  />
                </Link>
              ))}
          </div>
        )}
        {!isListView && <div className="seperation-line"></div>}
        <div className="datasets">
          <div className="heading">All</div>
          <div
            className="view-all"
            onClick={handleAllDatasets}
            style={{ cursor: "pointer" }}
          >
            <div className="view-all-text">View All</div>
            <div className="next-icon">
              <GoChevronRight />
            </div>
          </div>
        </div>
        {isDatasetsListLoading ? (
          <Loader />
        ) : (
          <>
            {!isListView ? (
              <div className="all-datasets-grid">
                {oceanDatasets.results.map((item) => {
                  return (
                    <Link
                      key={item.uid}
                      to={`/product-listing/${item.uid}`}
                      style={{ textDecoration: "none" }}
                    >
                      <GridViewDatasetComponent
                        key={item.uid}
                        title={item.title}
                        description={item.description}
                        payment={item.price_type}
                        license={item.license}
                        dataset_format={item.dataset_format}
                        data_type={item.data_type}
                      />
                    </Link>
                  );
                })}
              </div>
            ) : (
              <div className="all-datasets-list">
                {oceanDatasets.results.map((item) => (
                  <Link
                    key={item.uid}
                    to={`/product-listing/${item.uid}`}
                    style={{ textDecoration: "none" }}
                  >
                    <ListViewDatasetComponent
                      title={item.title}
                      description={item.description}
                      payment={item.price_type}
                      license={item.license}
                      data_type={item.data_type}
                    />
                  </Link>
                ))}
              </div>
            )}
          </>
        )}
        {!isListView && <div className="seperation-line"></div>}
        <div className="datasets">
          <div className="heading">Trending</div>
          <div className="view-all">
            <div className="view-all-text">View All</div>
            <div className="next-icon">
              <GoChevronRight />
            </div>
          </div>
        </div>
        {!isListView ? (
          <div className="popular-datasets-grid">
            {oceanDatasets.results
              .slice(oceanDatasets.results.length - 3)
              .map((item) => (
                <Link
                  key={item.uid}
                  to={`/product-listing/${item.uid}`}
                  style={{ textDecoration: "none" }}
                >
                  <GridViewDatasetComponent
                    title={item.title}
                    description={item.description}
                    payment={item.price_type}
                    license={item.license}
                    dataset_format={item.dataset_format}
                    data_type={item.data_type}
                  />
                </Link>
              ))}
          </div>
        ) : (
          <div className="popular-datasets-list">
            {oceanDatasets.results
              .slice(oceanDatasets.results.length - 3)
              .map((item) => (
                <Link
                  key={item.uid}
                  to={`/product-listing/${item.uid}`}
                  style={{ textDecoration: "none" }}
                >
                  <ListViewDatasetComponent
                    title={item.title}
                    description={item.description}
                    payment={item.price_type}
                    license={item.license}
                    data_type={item.data_type}
                  />
                </Link>
              ))}
          </div>
        )}
        {!isListView && !isDatasetsListLoading && (
          <div className="seperation-line"></div>
        )}
      </div>
    </div>
  );
};

export default CategoryViewComponent;
