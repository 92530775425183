import React, { Component, createRef } from "react";
import "../styles/loginComponent.scss";
import "../styles/commonStyle.scss";
import "../styles/form.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as accountActions from "../actions/accountActions";
import FormValidationErrorContainer from "./pure/formValidationErrorContainer";
import {
  ImgLoginBgEclipse,
  ImgNlg,
  ImgOMTLogo,
  ImgTBBCLogo,
  ImgWelcomeTBBC,
  Logo,
} from "../images";
import Loader from "./loaderComponent";
import { FormUtils } from "../utils/formUtils";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import HgDropdownComponent from "./pure/hgDropdownComponent";
import { COUNTRY_DATA } from "../config";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button } from "@mui/material";

const countryList = COUNTRY_DATA.map((item) => {
  return {
    label: item["Country Name"],
    value: item["Country Name"],
    phoneCode: item["Phone Code"],
    countryCode: item["ISO2"],
    countryCode2: item["ISO3"],
  };
});

class SingupComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      username: "",
      password: "",
      confirmPassword: "",
      organisation: "",
      country: "",
      countryCode: "",
      isEulaAccepted: true,
      firstnameValid: true,
      lastnameValid: true,
      usernameValid: true,
      passwordValid: true,
      confirmPasswordValid: true,
      organisationValid: true,
      countryValid: true,
      firstnameErrorMessage: "Enter your first name",
      lastnameErrorMessage: "Enter your last name",
      usernameErrorMessage: "Enter your email address",
      passwordErrorMessage: "Enter your password",
      confirmPasswordErrorMessage: "Please confirm password",
      eulaCheckErrorMessage: "Please accept EULA",
      organisationErrorMessage: "Please enter your organization",
      countryErrorMessage: "Please select your country",
      passwordRules: {
        minLength: false,
        hasSymbols: false,
        hasBothCases: false,
        hasNumber: false,
      },
      PasswordVisible: false,
    };
    this.recaptchaRef = createRef(null);
  }

  loadRecaptcha = async () => {
    const captchaToken = await this.recaptchaRef.current.executeAsync();
    this.recaptchaRef.current.reset();
    return captchaToken;
  };

  componentWillUnmount() {
    this.props.actions.accountStateReset();
  }

  onBlur = (e) => {
    const { name, value } = e.target;
    this.validateField(name, value);
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.validateField(name, value);
  };

  handleEyeClick = () => {
    this.setState((prevState) => ({
      PasswordVisible: !prevState.PasswordVisible,
    }));
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.props.actions.signup(
        this.state.firstname,
        this.state.lastname,
        this.state.username.toLowerCase(),
        this.state.password,
        this.state.confirmPassword,
        this.state.country,
        this.state.countryCode,
        ""
      );
    }
    // this.loadRecaptcha().then(captchaToken => {
    //     if (this.validateForm()) {
    //         this.props.actions.signup(this.state.firstname, this.state.lastname, this.state.username, this.state.password, this.state.confirmPassword, this.state.organisation, captchaToken);
    //     }
    // });
  };

  validateField = (name, value) => {
    if (name === "confirmPassword") {
      const { fieldValid, errorMessage } = FormUtils.validateFormField(
        name,
        value,
        this.state.password
      );
      this.updateFormState(name, value, fieldValid, errorMessage);
    } else if (name === "password") {
      const { fieldValid, errorMessage, comparedValueValid } =
        FormUtils.validateFormField(name, value, this.state.confirmPassword);
      this.updateFormState(
        name,
        value,
        fieldValid,
        errorMessage,
        comparedValueValid
      );
    } else {
      const { fieldValid, errorMessage } = FormUtils.validateFormField(
        name,
        value
      );
      this.updateFormState(name, value, fieldValid, errorMessage);
    }
  };

  updateFormState = (
    name,
    value,
    fieldValid,
    errorMessage,
    comparedValueValid = true
  ) => {
    const state = {
      [name]: value,
      [name + "Valid"]: fieldValid,
      [name + "ErrorMessage"]: errorMessage,
    };
    if (name === "password") {
      state["confirmPasswordValid"] = comparedValueValid;
    }
    this.setState(state);
  };

  validateForm = () => {
    if (
      this.state.username.length > 0 &&
      this.state.usernameValid &&
      this.state.password.length > 0 &&
      this.state.passwordValid &&
      this.state.confirmPassword.length > 0 &&
      this.state.confirmPasswordValid &&
      // && (this.state.organisation.length > 0 && this.state.organisationValid)
      this.state.firstname.length > 0 &&
      this.state.firstnameValid &&
      this.state.lastname.length > 0 &&
      this.state.lastnameValid &&
      this.state.country.length > 0 &&
      this.state.countryValid
    ) {
      return true;
    } else {
      if (this.state.firstname.length == 0) {
        this.setState({
          firstnameValid: false,
          firstnameErrorMessage: "Enter your first name",
        });
      }

      if (this.state.lastname.length == 0) {
        this.setState({
          lastnameValid: false,
          lastnameErrorMessage: "Enter your last name",
        });
      }

      if (this.state.username.length == 0) {
        this.setState({
          usernameValid: false,
          usernameErrorMessage: "Enter your email address",
        });
      }

      if (this.state.country.length == 0) {
        this.setState({
          countryValid: false,
        });
      }

      if (this.state.password.length == 0) {
        this.setState({
          passwordValid: false,
          passwordErrorMessage: "Enter your password",
        });
      }
      if (this.state.confirmPassword.length == 0) {
        this.setState({
          confirmPasswordValid: false,
          confirmPasswordErrorMessage: "Please confirm password",
        });
      }
      if (this.state.isEulaAccepted === false) {
        this.setState({
          isEulaAccepted: false,
          eulaCheckErrorMessage: "Please accept EULA",
        });
      }
    }
  };

  onResendVerifyEmailHandler = () => {
    this.props.actions.resentVerifyEmail(this.state.username);
  };

  eulaHandler = (e) => {
    this.setState({ isEulaAccepted: !this.state.isEulaAccepted });
  };

  customSelectionComponent = (item) => {
    return `${item.value} (${item.countryCode2})`;
  };

  onCountrySelect = (item) => {
    this.setState({
      country: item.value,
      countryCode: item.countryCode2,
      countryValid: true,
    });
  };

  render() {
    return (
      <div className="login-component">
        {/* <header className="header">
                    <Link to={'/'}><img src={Logo} /></Link>
                </header> */}
        <main className="main">
          {this.props.isLoading && <Loader />}
          {!this.props.isSignupSuccess ? (
            <>
              <div style={{ marginBottom: "24px" }}>
                <img src={ImgTBBCLogo} />
              </div>
              <div className="section-heading">ACCOUNT SIGN UP</div>
              <form
                className="dsg-form"
                onSubmit={this.onSubmit}
                noValidate={false}
              >
                <div className="form-field no-margin">
                  <input
                    type="text"
                    id="firstname"
                    name="firstname"
                    className="form-control border-bottom-only"
                    placeholder="first name"
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    value={this.state.firstname}
                  />
                  {/* <span className="form-icon user"></span> */}
                  {!this.state.firstnameValid && (
                    <FormValidationErrorContainer
                      errMessage={this.state.firstnameErrorMessage}
                    />
                  )}
                </div>
                <div className="form-field no-margin">
                  <input
                    type="text"
                    id="lastname"
                    name="lastname"
                    className="form-control border-bottom-only"
                    placeholder="last name"
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    value={this.state.lastname}
                  />
                  {/* <span className="form-icon user"></span> */}
                  {!this.state.lastnameValid && (
                    <FormValidationErrorContainer
                      errMessage={this.state.lastnameErrorMessage}
                    />
                  )}
                </div>
                <div className="form-field no-margin">
                  <input
                    type="text"
                    id="username"
                    name="username"
                    className="form-control border-bottom-only"
                    placeholder="email address"
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    value={this.state.username}
                  />
                  {/* <span className="form-icon email"></span> */}
                  {!this.state.usernameValid && (
                    <FormValidationErrorContainer
                      errMessage={this.state.usernameErrorMessage}
                    />
                  )}
                </div>
                <div className="form-field">
                  <HgDropdownComponent
                    listItems={countryList}
                    placeholder={"select country"}
                    customSelectionComponent={this.customSelectionComponent}
                    onSelectHandler={this.onCountrySelect}
                    enableSearch={true}
                    searchKeys={["value"]}
                  />
                  {/* <input type="text"
                                        id="country"
                                        name="country"
                                        className="form-control border-bottom-only"
                                        placeholder="country"
                                        onBlur={this.onBlur}
                                        onChange={this.onChange}
                                        value={this.state.country} /> */}
                  {/* <span className="form-icon email"></span> */}
                  {!this.state.countryValid && (
                    <FormValidationErrorContainer
                      errMessage={this.state.countryErrorMessage}
                    />
                  )}
                </div>
                <div className="form-field">
                  <div className="form-field-label mui">create password</div>
                  <input
                    type={this.state.PasswordVisible ? "text" : "password"}
                    id="password"
                    name="password"
                    className="form-control"
                    placeholder="password"
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    value={this.state.password}
                  />
                  {/* <span className="form-icon password"></span> */}
                  <span
                    className="password-toggle-icon"
                    onClick={this.handleEyeClick}
                  >
                    {this.state.PasswordVisible ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </span>
                  {!this.state.passwordValid && (
                    <FormValidationErrorContainer
                      errMessage={this.state.passwordErrorMessage}
                      isPermanent={true}
                    />
                  )}
                </div>
                <div className="form-field">
                  <div className="form-field-label mui">confirm password</div>
                  <input
                    type={this.state.PasswordVisible ? "text" : "password"}
                    id="conf-password"
                    name="confirmPassword"
                    className="form-control"
                    placeholder="password"
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    value={this.state.confirmPassword}
                  />
                  {/* <span className="form-icon password"></span> */}
                  <span
                    className="password-toggle-icon"
                    onClick={this.handleEyeClick}
                  >
                    {this.state.PasswordVisible ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </span>
                  {!this.state.confirmPasswordValid && (
                    <FormValidationErrorContainer
                      errMessage={this.state.confirmPasswordErrorMessage}
                      isPermanent={true}
                    />
                  )}
                </div>
                {/* <div className="form-field">
                                    <input type="text"
                                        id="organisation"
                                        name="organisation"
                                        className="form-control"
                                        placeholder="Organization"
                                        onBlur={this.onBlur}
                                        onChange={this.onChange}
                                        value={this.state.organisation} />
                                    
                                </div> */}
                {/* <span className="form-icon organisation"></span> */}
                {/* {!this.state.organisationValid && <FormValidationErrorContainer errMessage={this.state.organisationErrorMessage} />} */}
                {/* <ReCAPTCHA
                                    ref={this.recaptchaRef}
                                    sitekey={"6LeM5wMkAAAAAMf0sMauAcc4a03MxjohPtE-eRCF"}
                                    size="invisible"
                                /> */}
                <div className="form-field fgt-pwd">
                  <input
                    type="checkbox"
                    id="remember-me"
                    name="remember-me"
                    hidden={true}
                    defaultChecked={this.state.isEulaAccepted}
                    value={this.state.isEulaAccepted}
                    onChange={this.eulaHandler}
                  />
                  <label htmlFor="remember-me" className="dsg-checkbox">
                    Read and Accept EULA
                  </label>
                </div>
                {!this.state.isEulaAccepted && (
                  <FormValidationErrorContainer
                    errMessage={this.state.eulaCheckErrorMessage}
                    isPermanent={true}
                  />
                )}
                <Button
                  variant="contained"
                  sx={{ "&:hover": { backgroundColor: "#303030" } }}
                  className="submit"
                  type="submit"
                  disabled={this.props.isLoading}
                >
                  Sign Up
                </Button>
                {this.props.formError && (
                  <FormValidationErrorContainer
                    errMessage={this.props.formError}
                  />
                )}
                <div className="sign-up">
                  <Link to={"/"}>
                    <Button
                      variant="outlined"
                      sx={{
                        color: "#303030",
                        "&:hover": {
                          border: "1px solid #303030",
                          backgroundColor: "#eeeeee",
                        },
                      }}
                      className="button"
                      type="button"
                      disabled={this.props.isLoading}
                    >
                      Log In
                    </Button>
                  </Link>
                </div>
              </form>
            </>
          ) : (
            <>
              <div className="section-heading">Email Verification Needed</div>
              <div className="helper-text">
                Thanks for signing up. Please check your email to verify your
                account.
              </div>
              <div className="helper-text">
                Can't find it?{" "}
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={this.onResendVerifyEmailHandler}
                >
                  Click here to resend the email verification.
                </span>
              </div>
            </>
          )}
        </main>
        <img src={ImgWelcomeTBBC} className="welcome-tbbc" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.account.isSingupLoading,
    isSignupSuccess: state.account.isSignupSuccess,
    formError: state.account.formError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(accountActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SingupComponent);
