import React, { useEffect, useState } from "react";
import "../../styles/multiSelect.scss";
import PillButton from "./pillButton";
import { v4 as uuid } from "uuid";
import axios from "axios";
import { API_URL } from "../../config";

const MultiSelect = ({ selectedKeyword, setSelectedKeyword, isFormValid, setIsFormValid }) => {
  const [searchText, setSearchText] = useState("");
  const [suggestedList, setSuggestedList] = useState([]);
  // select the keyword from list
  const handleKeywordSelection = (index, id) => {
    const alreadySelectedKeyword = selectedKeyword.find(
      (keywordObj) => keywordObj.id === id
    );
    if (!alreadySelectedKeyword) {
      setSelectedKeyword((prevState) => ({
        ...prevState,
        selectedKeyword: [...selectedKeyword, suggestedList[index]],
        isFormValid: true
      }));
      setSearchText("");
    }
  };

  // remove the selected keyword
  const removeSelectedKeyword = (selectedId) => {
    const removedKeyword = selectedKeyword.filter(
      (keyword, i) => selectedId !== keyword.id
    );
    setSelectedKeyword((prevState) => {
      if (!removedKeyword.length) {
        return {
          ...prevState,
          selectedKeyword: removedKeyword,
          isFormValid: false,
        };
      }
      return {
        ...prevState,
        selectedKeyword: removedKeyword,
      };
    });
  };

  // call the API endpoint for keywords
  const searchKeyword = async (searchText) => {
    if (searchText.length) {
      const result = await axios.get(
        `${API_URL}/auth/search/?keyword=${searchText}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("access_token"))
              .token,
          },
        }
      );

      const filteredKeyword = result.data?.suggestions.filter(
        (suggestObj) =>
          !selectedKeyword.filter((selectObj) => selectObj.id === suggestObj.id)
            .length
      );

      if (filteredKeyword.length > 5) {
        setSuggestedList(filteredKeyword.splice(0, 5));
      } else {
        setSuggestedList(filteredKeyword);
      }
    }
  };

  // adds a new keyword if not appear on the list
  const addNewKeyword = (e) => {
    if (e.keyCode === 13 || e.key === "Enter") {
      let newKeyword = {
        id: uuid(),
        metadata: {
          Category: "",
          Term: "",
          Topic: "",
          Variable_Level_1: e.target.value,
        },
        similarity: 0.0,
      };
      if (e.target.value.length >= 2) {
        setSelectedKeyword((prevState) => ({
          ...prevState,
          selectedKeyword: [...selectedKeyword, newKeyword],
          isFormValid: true
        }));
        setSearchText("");
        // setIsFormValid(true);
      }
    }
  };

  useEffect(() => {
    // de-boucing the API call
    const timer = setTimeout(() => {
      searchKeyword(searchText);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchText]);
  return (
    <>
      <div className="multi-select-container">
        {selectedKeyword?.map((keyword, index) => (
          <PillButton
            key={index}
            keyword={
              keyword.metadata.Term &&
              keyword.metadata.Category &&
              keyword.metadata.Topic
                ? `${
                    keyword.metadata?.Variable_Level_1 &&
                    keyword.metadata?.Variable_Level_1 + " : "
                  } ${
                    keyword.metadata?.Term && keyword.metadata?.Term + " : "
                  } ${
                    keyword.metadata?.Topic && keyword.metadata?.Topic + " : "
                  } ${keyword.metadata?.Category}`
                : keyword.metadata.Variable_Level_1
            }
            removeKeyword={() => removeSelectedKeyword(keyword.id)}
          />
        ))}
        <div>
          <input
            className="multi-input"
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={addNewKeyword}
            type="text"
            value={searchText}
            placeholder="E.g. Temperature"
          />
          {suggestedList.length && searchText.length ? (
            <div className="suggested-list">
              <ul>
                {suggestedList.map((suggestedObj, index) => {
                  return (
                    <li
                      style={
                        selectedKeyword.find(
                          (selectedObj) => selectedObj.id === suggestedObj.id
                        ) && { background: "#dde8f4", borderRadius: "8px" }
                      }
                      onClick={() =>
                        handleKeywordSelection(index, suggestedObj.id)
                      }
                      key={suggestedObj.id}
                      className="suggested-item"
                    >
                      {suggestedObj.metadata.Variable_Level_1
                        ? suggestedObj.metadata.Variable_Level_1
                        : `${
                            suggestedObj.metadata?.Term &&
                            suggestedObj.metadata?.Term + " : "
                          } ${
                            suggestedObj.metadata?.Topic &&
                            suggestedObj.metadata?.Topic + " : "
                          } ${suggestedObj.metadata?.Category}`.length > 80
                        ? `${
                            suggestedObj.metadata?.Term &&
                            suggestedObj.metadata?.Term + " : "
                          } ${
                            suggestedObj.metadata?.Topic &&
                            suggestedObj.metadata?.Topic + " : "
                          } ${suggestedObj.metadata?.Category}`.slice(0, 80) +
                          "..."
                        : `${
                            suggestedObj.metadata?.Term &&
                            suggestedObj.metadata?.Term + " : "
                          } ${
                            suggestedObj.metadata?.Topic &&
                            suggestedObj.metadata?.Topic + " : "
                          } ${suggestedObj.metadata?.Category}`}
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default MultiSelect;
