import React, { useState } from "react";
import "../../styles/publishedActionsComponent.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { uploadDataset } from "../../actions/publishActions";
import { PUBLISH_DATASET_STATUS } from "../../config";
import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const PublishedActionsComponent = ({
  status,
  child_dataset_id,
  licenseType,
  datasetId,
  uid,
}) => {
  const [activeButton, setActiveButton] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isButtonDisable, setIsButtonDisable] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleButtonClick = (action) => {
    setActiveButton(action);

    // Navigate to the product-preview route if the action is "Preview"
    if (action === "Preview") {
      history.push("/product-preview/" + datasetId);
    }

    if (action === "Publish") {
      dispatch(
        uploadDataset(
          child_dataset_id,
          PUBLISH_DATASET_STATUS.PUBLISHED,
          licenseType,
          uid
        )
      );
      history.push("/publishing-manager");
    } else if (action === "Unpublish") {
      setIsModalOpen(true);
    } else if (action === "Cancel Edits") {
      history.push("/publishing-manager");
    }
  };

  const handleUnpublish = () => {
    dispatch(
      uploadDataset(
        child_dataset_id,
        PUBLISH_DATASET_STATUS.UNPUBLISHED,
        licenseType,
        uid
      )
    );
    history.push("/publishing-manager");
  };

  return (
    <div className="published-actions-component">
      <div className="published-actions">
        <div
          className={`published ${status === "DRAFT" ? "draft-border" : ""}`}
        >
          {status}
        </div>
        <div className="actions">
          <div className="action">Actions</div>
          <div className="all-actions">
            {["Remove", "Cancel Edits", "Preview", "Save to Drafts"].map(
              (action) => (
                <button
                  key={action}
                  className={`action-btn ${
                    activeButton === action ? "active" : ""
                  }`}
                  onClick={() => handleButtonClick(action)}
                >
                  {action}
                </button>
              )
            )}

            <button
              className={`publish-btn ${
                activeButton === "Publish" ? "active" : ""
              }`}
              onClick={() =>
                handleButtonClick(
                  (status === PUBLISH_DATASET_STATUS.DRAFT && "Publish") ||
                    (status === PUBLISH_DATASET_STATUS.UNPUBLISHED &&
                      "Publish") ||
                    (status === PUBLISH_DATASET_STATUS.PUBLISHED && "Unpublish")
                )
              }
            >
              {status === PUBLISH_DATASET_STATUS.PUBLISHED
                ? "Unpublish"
                : "Publish"}
            </button>
            <Modal isOpen={isModalOpen} size="lg" centered={true} className="unpublished-modal">
              <ModalHeader>
                <h5>Unpublish Dataset</h5>
              </ModalHeader>
              <ModalBody>
                <p>
                  You are returning a published dataset to draft status. All
                  users of this dataset will no longer have access to this
                  dataset. You can publish this dataset again from your drafts.
                </p>
                <div>Note :</div>
                <p>
                  • Users that have added this to their datasets will still have
                  it in their datasets
                </p>
                <p>• Users who have downloaded this dataset will retain it</p>
                <p>
                  • This listing will not be discoverable to others unless you
                  republish
                </p>
              </ModalBody>
              <ModalFooter>
                <div className="unpublish-checkbox">
                  <span>Are you Sure?</span>
                  <input
                    type="checkbox"
                    onClick={(e) => {
                      setIsButtonDisable(!e.target.checked);
                    }}
                  />
                </div>
                <hr />
                <div className="unpublish-actions">
                  <button
                    onClick={() => setIsModalOpen(false)}
                    className="upublish-cancle"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={isButtonDisable}
                    onClick={handleUnpublish}
                    className="upublish-status"
                  >
                    Unpublish
                  </button>
                </div>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublishedActionsComponent;
