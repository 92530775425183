import React from "react";
import "../../styles/pillButton.scss";
import { ImgCross } from "../../images";
const PillButton = ({ keyword, removeKeyword }) => {
  return (
    <div className="pill-container">
      {keyword}

      <img onClick={removeKeyword} src={ImgCross} alt="delete" width={14} role="button" />
    </div>
  );
};

export default PillButton;
