import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import sessionUtils from '../../utils/sessionUtils';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import '../../styles/modalComponent.scss';
// import '../../styles/datasetDescriptionEditModal.scss'
import '../../styles/geospatialVariableEditModal.scss'
import { getVersionString } from '../../utils/utils';
import DatasetPreviewComponent from './datasetPreviewComponent';
import HgButtonComponent from './hgButtonComponent';
import SafeHtmlComponent from './safeHtmlComponent';
import {Button} from "@mui/material"
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import Switch from '@mui/material/Switch';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Divider from "@mui/material/Divider";
import { IconButton} from "@mui/material";
import { parseDatasetSchema } from '../../utils/utils';
const { REACT_APP_API_URL } = process.env;
export const API_URL = REACT_APP_API_URL;


const CustomTextField = styled(TextField)({
    marginLeft:'63px',
    display: "flex",
    height: "72px",
    width: "100%",
    gap: "10px",
    alignSelf: "stretch",
    borderRadius: "8px",
    border: "1px dashed #51606F",
    backgroundColor: "#FCFCFF",
    boxShadow:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
    "& .MuiInputBase-root": {
      height: "100%",
      padding: "0",
    },
    "& .MuiInputBase-input": {
      padding: "0 15px",
      fontFamily: "SpaceGrotesk",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
    },
  });

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

function GeospatialVariableEditPopupComponent(props) {
    const { description, toggle,  modal, onAccept, name, version, latitude, longitude, dataset_root_uid, dataset_child_uid, datasetSchema} = props
    const [editableLatitude, setEditableLatitude] = useState(latitude || "");
    const [editableLongitude, setEditableLongitude] = useState(longitude || "");

    useEffect(() => {
        setEditableLatitude(latitude || "");
    }, [latitude]);

    useEffect(() => {
        setEditableLongitude(longitude || "");
    }, [longitude]);



    const [isToggled, setIsToggled] = useState(false);
    const handleToggle = (event) => {
        setIsToggled(event.target.checked);
      };
    
      const handleLatitudeChange = (event) => {
        setEditableLatitude(event.target.value);
    }

    const handleLongitudeChange = (event) => {
        setEditableLongitude(event.target.value);
    }

    const [isLatitudeOpen, setIsLatitudeOpen] = useState(false);
    const [isLongitudeOpen, setIsLongitudeOpen] = useState(false);
    const [selectedLatitudeOption, setSelectedLatitudeOption] = useState("No Geospatial Coordinates");
    const [selectedLongitudeOption, setSelectedLongitudeOption] = useState("No Geospatial Coordinates");
    const [columnNames, setColumnNames] = useState([]);
    const dropdownRef = useRef(null);

    const toggleLatitudeDropdown = () => {
      setIsLatitudeOpen(!isLatitudeOpen);
      setIsLongitudeOpen(false); // Close the other dropdown
    };
  
    const toggleLongitudeDropdown = () => {
      setIsLongitudeOpen(!isLongitudeOpen);
      setIsLatitudeOpen(false); // Close the other dropdown
    };

    // const selectOption = (option) => {
    //   setSelectedOption(option);
    //   setIsOpen(false); // Close dropdown after selection
    // };

    useEffect(() => {
      if (datasetSchema) {
        const columns = parseDatasetSchema(datasetSchema).map((item) => item["index"]);
        setColumnNames(columns);
      }
    }, [datasetSchema]);

    const handleSelectLatitudeOption = (option) => {
      setSelectedLatitudeOption(option);
      setIsLatitudeOpen(false); // Close dropdown
    };
  
    const handleSelectLongitudeOption = (option) => {
      setSelectedLongitudeOption(option);
      setIsLongitudeOpen(false); // Close dropdown
    };



    const handleSave = async () => {
      if (!selectedLatitudeOption || !selectedLongitudeOption) {
        alert("Please select both Latitude and Longitude variables.");
        return;
      }
      
      const requestBody = {
          dataset_root_uid: dataset_root_uid,
          dataset_child_uid: dataset_child_uid,
          latitude: selectedLatitudeOption,
          longitude: selectedLongitudeOption,
          apply_all_geo: isToggled,
          
      };
  
      const token = sessionUtils.getToken("access_token");
      
      const headers = {
        'Authorization': `Bearer ${token}`, // Replace with your actual token
        'Content-Type': 'application/json',
    };
  
      try {
          const response = await axios.put(
              `${API_URL}/auth/v1/update-dataset/geospatial/`,
              requestBody,
              {headers}
          );
          if (response.status === 200) {
            // onSave(editableLatitude, editableLongitude, isToggled);
            
              onAccept(); // Trigger callback if provided
              
              // toggle(); // Close the modal
            
          }
      } catch (error) {
          console.error("Error updating geospatial data:", error);
          alert("Please select both Latitude and Longitude variables.");
      }
  };

    return (
        <div className='geospatial-variable-edit-component'>
            <Modal style={{width:'800px'}} isOpen={modal} toggle={toggle} size={"md"} className={"geospatial-variable-edit-component"}  {...props}>
                <ModalHeader toggle={toggle}>Edit Geospatial Variables
                </ModalHeader>
                <ModalBody>

                <div className="content">
                    <div className="label-text-2">Dataset Title</div>
                  <div className="div-wrapper">
                    <p className="input-text-2">{name}</p>
                  </div>
                </div>

                <div className="content">
                    <div className="label-text-2">Dataset Version</div>
                  <div className="div-wrapper">
                    <p className="input-text-2">{version}</p>
                  </div>
                </div>



                <div className="content">
                    <div className="label-text-3">EDIT VARIABLES</div>
                </div>

                <div className="content" style={{marginLeft:'65px'}}>
                    <div className="label-text-3">GEOGRAPHY</div>
                </div>


                <div className="text-field-wrapper99">
              <div
                className="state-layer-23"
                ref={dropdownRef} // Attach ref to the main container
                onClick={toggleLatitudeDropdown}
              >
                <div className="content11">
                  <div className="div-wrapper1">
                    <div className="label-text-2">Primary Latitude Variable</div>
                  </div>
                  <div className="div-wrapper1">
                    <div className="input-text-2">
                      <div className="input-text-2">{selectedLatitudeOption}</div>
                    </div>
                  </div>
                </div>
                <IconButton>
                  <ArrowDropDownIcon />
                </IconButton>
                {isLatitudeOpen && (
                  <div className="dropdown-options2" style={{ position: 'absolute',backgroundColor: 'white',border: '1px solid #ccc',width: 'auto',
                    marginTop: '57%',
                    zIndex: 10}}>
                    {columnNames.map((option, index) => (
                      <div
                      style={{ padding: '10px',
                        cursor: 'pointer'}}
                        key={index}
                        className="dropdown-option"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent click event from closing the dropdown
                          handleSelectLatitudeOption(option);
                        }}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <Divider
                style={{
                  background: "black",
                  width: "100%",
                  marginLeft: "0px",
                  opacity: 1,
                }}
                variant="middle"
              />
            </div>


           
            <div className="text-field-wrapper99">
              <div
                className="state-layer-23"
                ref={dropdownRef} // Attach ref to the main container
                onClick={toggleLongitudeDropdown}
              >
                <div className="content11">
                  <div className="div-wrapper1">
                    <div className="label-text-2">Primary Longitude VARIABLE</div>
                  </div>
                  <div className="div-wrapper1">
                    <div className="input-text-2">
                      <div className="input-text-2">{selectedLongitudeOption}</div>
                    </div>
                  </div>
                </div>
                <IconButton>
                  <ArrowDropDownIcon />
                </IconButton>
                {isLongitudeOpen && (
        <div className="dropdown-options2" style={{ position: 'absolute',backgroundColor: 'white',border: '1px solid #ccc',width: 'auto',
        marginTop: '57%',
        zIndex: 10}}>
          {columnNames.map((option, index) => (
            <div
            style={{ padding: '10px',
            cursor: 'pointer'}}
              key={index}
              className="dropdown-option"
              onClick={(e) => {
                e.stopPropagation(); // Prevent click event from closing the dropdown prematurely
                handleSelectLongitudeOption(option);
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
              </div>
              <Divider
                style={{
                  background: "black",
                  width: "100%",
                  marginLeft: "0px",
                  opacity: 1,
                }}
                variant="middle"
              />
            </div>

                    {/* <div style={{wordWrap:'break-word', marginLeft:'46px'}} className='pd-8'><SafeHtmlComponent data={description} /></div> */}
                </ModalBody>
                <ModalFooter>
                    <div className='togglebtn'>   
                <div
                style={{display:"flex", alignItems:'center'}} >
      <Switch
        checked={isToggled}
        onChange={handleToggle}
        color="primary" // 'primary', 'secondary', or 'default'
      />
      <div variant="body1">
        {isToggled ? "Changes applied to all the versions" : "Apply changes to all versions"}
      </div>
    
    </div>
                    {/* <HgButtonComponent buttonText={"Close"} isPrimary={false} onclickHandler={() => onAccept()} /> */}
                    <Button
                    className='btnspecial'
                variant="outlined"
                onClick={handleSave}
              >
                SAVE
              </Button>

              </div>
                </ModalFooter>
            </Modal>
            </div>
    );
}

export default GeospatialVariableEditPopupComponent;