import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "../../styles/dataCategoryGradientComponent.scss";

const gridItems = [
  {
    title: "Datasets",
    background:
      "radial-gradient(ellipse 68.47% 162.09% at 50% 50%, #DFF9FF 0%, #ADCBEC 100%)",
    description:
      "This includes numerical data collected through various instruments, such as sensors on buoys, ARGO floats, or satellite-based measurement systems. It typically involves physical parameters like temperature, salinity, pH, and ocean currents, used for climate monitoring, oceanographic studies, and marine research.",
    path: "datasets",
  },
  {
    title: "Charts",
    background:
      "radial-gradient(91.86% 222.6% at 18.63% 50%, #D1D1D1 0%, #A3A7AC 100%)",
    description:
      "This includes numerical data collected through various instruments, such as sensors on buoys, ARGO floats, or satellite-based measurement systems. It typically involves physical parameters like temperature, salinity, pH, and ocean currents, used for climate monitoring, oceanographic studies, and marine research.",
    path: "charts",
  },
  {
    title: "Dashboards",
    background:
      "radial-gradient(91.86% 222.6% at 18.63% 50%, #D9D9D9 0%, #ADCBEC 100%)",
    description:
      "This includes numerical data collected through various instruments, such as sensors on buoys, ARGO floats, or satellite-based measurement systems. It typically involves physical parameters like temperature, salinity, pH, and ocean currents, used for climate monitoring, oceanographic studies, and marine research.",
    path: "dashboards",
  },
  {
    title: "Models",
    background:
      "radial-gradient(62.69% 124.06% at 45.28% 100%, #D3DAEA 0%, #9FBEE1 100%)",
    description:
      "This includes numerical data collected through various instruments, such as sensors on buoys, ARGO floats, or satellite-based measurement systems. It typically involves physical parameters like temperature, salinity, pH, and ocean currents, used for climate monitoring, oceanographic studies, and marine research.",
    path: "models",
  },
  {
    title: "Pipelines",
    background:
      "radial-gradient(68.19% 157.3% at 50% 88.44%, #B0D1D3 0%, #8EC5C9 100%)",
    description:
      "This includes numerical data collected through various instruments, such as sensors on buoys, ARGO floats, or satellite-based measurement systems. It typically involves physical parameters like temperature, salinity, pH, and ocean currents, used for climate monitoring, oceanographic studies, and marine research.",
    path: "pipelines",
  },
];

const DataCategoryGradientComponent = () => {
  const history = useHistory();
  const handleCategory = (category) => {
    console.log(category);
    history.push(`/category-view/${category}`);
  };
  return (
    <div className="grid-container">
      {gridItems.map((item) => (
        <div key={item.title} className="card">
          <div
            className="category-card"
            style={{ background: item.background }}
            onClick={() => handleCategory(item.path)}
          ></div>
          <div className="category-heading">{item.title}</div>
        </div>
      ))}
    </div>
  );
};
export default DataCategoryGradientComponent;
