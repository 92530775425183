import AccountApi from "../api/accountApi";
import { STORAGE_KEY } from "../config";
import { localStorageGetObject, localStorageSetObject } from "../utils/localStorageUtils";
import { ACCOUNT_ACTIONS } from "./actionTypes";

/**
 * Get recently used models
 * @returns 
 */
export function signup(firstname, lastname, email, password1, confirmPassword, country, countryCode, organisationName, captchaToken) {
    return (dispatch) => {
        dispatch(signupLoading());
        return AccountApi.signup(firstname, lastname, email, password1, confirmPassword, country, countryCode, organisationName, captchaToken).then((resp) => {
            console.log(resp);
            dispatch(signupSuccess());
        }).catch(error => {
            console.log(`signup ${error}`);
            try {
                if (error.response.data.email[0]) {
                    dispatch(signupError(error.response.data.email[0]));
                } else {
                    dispatch(signupError(error.response.statusText));
                }
            } catch {
                dispatch(signupError(error.response.data.error[0]));
                console.log(error);
            }
        });
    }
}

/**
 * Recently used models loading
 * @returns 
 */
function signupLoading() {
    return { type: ACCOUNT_ACTIONS.SIGNUP_LOADING };
}

/**
 * Recently used models success
 * @param {object} payload 
 * @returns 
 */
function signupSuccess(payload) {
    return { type: ACCOUNT_ACTIONS.SIGNUP_SUCCESS, payload };
}

/**
 * Recently used models error
 * @returns 
 */
function signupError(payload = "") {
    return { type: ACCOUNT_ACTIONS.SIGNUP_FAILURE, payload };
}

export function resentVerifyEmail(email) {
    return (dispatch) => {
        dispatch(signupLoading());
        return AccountApi.resentVerifyEmail(email).then(({data}) => {
            console.log(data);
            dispatch(signupSuccess());
        }).catch(error => {
            console.log(`signup ${error}`);
            if (error.response.data.email[0]) {
                dispatch(signupError(error.response.data.email[0]));
            } else {
                dispatch(signupError(error.response.statusText));
            }
        });
    }
}

export function verifyEmail(email) {
    return (dispatch) => {
        dispatch(verifyEmailLoading());
        return AccountApi.verifyEmail(email).then(({ data }) => {
            console.log(data);
            dispatch(verifyEmailSuccess());
        }).catch(error => {
            console.log(`verifyEmail ${error}`);
            dispatch(verifyEmailError());
        });
    }
}

function verifyEmailLoading() {
    return { type: ACCOUNT_ACTIONS.VERIFY_EMAIL_LOADING };
}

function verifyEmailSuccess() {
    return { type: ACCOUNT_ACTIONS.VERIFY_EMAIL_SUCCESS };
}

function verifyEmailError() {
    return { type: ACCOUNT_ACTIONS.VERIFY_EMAIL_ERROR };
}

export function getResetPasswordLink(email) {
    return (dispatch) => {
        dispatch(getResetPasswordLinkLoading());
        return AccountApi.getResetPasswordLink(email).then(({ data }) => {
            console.log(data);
            dispatch(getResetPasswordLinkSuccess());
        }).catch(error => {
            console.log(`getResetPasswordLink ${error}`);
            if (error.response.status === 500) {
                dispatch(getResetPasswordLinkError(error.response.statusText));
            } else {
                dispatch(getResetPasswordLinkError(error.response.data));
            }
        });
    }
}

function getResetPasswordLinkLoading() {
    return { type: ACCOUNT_ACTIONS.RESET_PASSWORD_LOADING };
}

function getResetPasswordLinkSuccess() {
    return { type: ACCOUNT_ACTIONS.RESET_PASSWORD_SUCCESS };
}

function getResetPasswordLinkError(payload = "") {
    return { type: ACCOUNT_ACTIONS.RESET_PASSWORD_ERROR, payload };
}

export function changePassword(oldPassword, password, confirmPassword) {
    return (dispatch) => {
        dispatch(changePasswordLoading());
        return AccountApi.changePassword(oldPassword, password, confirmPassword).then(({ data }) => {
            console.log(data);
            dispatch(changePasswordSuccess());

        }).catch(error => {
            console.log(`changePassword ${error}`);
            if (error.response.data.old_password) {
                dispatch(changePasswordError(error.response.data.old_password[0]));
            } else {
                dispatch(changePasswordError(error.response.statusText));
            }
        });
    }
}

function changePasswordLoading() {
    return { type: ACCOUNT_ACTIONS.CHANGE_PASSWORD_LOADING };
}

function changePasswordSuccess() {
    return { type: ACCOUNT_ACTIONS.CHANGE_PASSWORD_SUCCESS };
}

function changePasswordError(payload = "") {
    return { type: ACCOUNT_ACTIONS.CHANGE_PASSWORD_ERROR, payload };
}

export function changePasswordResetState() {
    return { type: ACCOUNT_ACTIONS.CHANGE_PASSWORD_RESET }
}

export function updateUserInfo(username, firstname, lastname,profilePicUrl, organisation) {
    console.log("Profile pic url actions ",profilePicUrl)
    return (dispatch) => {
        dispatch(changePasswordLoading());
        return AccountApi.updateUserInfo(username, firstname, lastname,profilePicUrl, organisation).then(({ data }) => {
            // console.log(data);
            // console.log("Profile pic url actions ",profilePicUrl)
            // localStorageSetObject(STORAGE_KEY.user_info, data);
            dispatch(getCurrentUserInfoFromCache());
            dispatch(changePasswordSuccess());
        }).catch(error => {
            console.log(`updateUserInfo ${error}`);
            if (error.response.data.username) {
                dispatch(changePasswordError(error.response.data.username[0]));
            } else {
                dispatch(changePasswordError(error.response.statusText));
            }
        });
    }
}

export function setResetPassword(password, confirmPassword, token) {
    return (dispatch) => {
        dispatch(setResetPasswordLoading());
        return AccountApi.setResetPassword(password, confirmPassword, token).then(({data}) => {
            console.log(data);
            dispatch(setResetPasswordSuccess());
        }).catch(error => {
            dispatch(setResetPasswordError(error.response.statusText));
        });
    }
}

function setResetPasswordLoading() {
    return { type: ACCOUNT_ACTIONS.SET_RESET_PASSWORD_LOADING };
}

function setResetPasswordSuccess() {
    return { type: ACCOUNT_ACTIONS.SET_RESET_PASSWORD_SUCCESS };
}

function setResetPasswordError(payload = "") {
    return { type: ACCOUNT_ACTIONS.SET_RESET_PASSWORD_ERROR, payload };
}

export function getCurrentUserInfoFromCache() {
    const payload = localStorageGetObject(STORAGE_KEY.user_info);
    if (payload) {
        return {type: ACCOUNT_ACTIONS.GET_CUREENT_USER_INFO_FROM_CACHE, payload};
    } else {
        const payload = {}
        return {type: ACCOUNT_ACTIONS.GET_CUREENT_USER_INFO_FROM_CACHE, payload}; 
    }
}

export function accountStateReset() {
    return { type: ACCOUNT_ACTIONS.ACCOUNT_STATE_RESET };
}