import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import HgButtonComponent from "./hgButtonComponent";
import "../../styles/productPricingComponent.scss";
import Switch from "@mui/material/Switch";
import { useDispatch } from "react-redux";
import { editBasicInfo } from "../../actions/publishActions";
import { Button } from "@mui/material";

const ProductPricingComponent = (props) => {
  const {
    modal,
    toggle,
    datasetName,
    publisher,
    attribution,
    subtitle,
    tags,
    publish_uid,
    price_type,
    subcription_type,
    product_cost,
    monthly_subcription_price,
    yearly_subcription_price,
  } = props;
  const [selectedCard, setSelectedCard] = useState("oneTimePayment");
  const [editMonthlySubscriptionPrice, setEditMonthlySubscriptionPrice] =
    useState(monthly_subcription_price);
  const [editProductCost, setEditProductCost] = useState(product_cost);
  const [editYearlySubscriptionPrice, setEditYearlySubscriptionPrice] =
    useState(yearly_subcription_price);

  const dispatch = useDispatch();

  useEffect(() => {
    setEditProductCost(product_cost);
    setEditMonthlySubscriptionPrice(monthly_subcription_price);
  }, [product_cost, monthly_subcription_price]);

  const handleSave = () => {
    dispatch(
      editBasicInfo(
        publish_uid,
        datasetName,
        attribution,
        price_type,
        publisher,
        subtitle,
        tags,
        subcription_type,
        editProductCost,
        editMonthlySubscriptionPrice,
        yearly_subcription_price
      )
    );
    toggle();
  };

  return (
    <div className="product-pricing-component">
      <Modal
        isOpen={modal}
        toggle={toggle}
        size={"md"}
        {...props}
        className="product-pricing-component"
      >
        <ModalHeader>
          <h3>Product Pricing</h3>
          <p>
            Basic instructions for creating a listing for Ocean of Data
            Publishing
          </p>
        </ModalHeader>
        <ModalBody>
          <div className="title">Product Title</div>
          <div className="product">Product Discovery</div>
          <div className="cards">
            {/* One-Time Payment Card */}
            <div
              className={`payment ${
                selectedCard === "oneTimePayment" ? "oneTimePayment" : ""
              }`}
              onClick={() => setSelectedCard("oneTimePayment")}
            >
              <p className="card-title">One Time Payment</p>
              <p>One time customer payment Terms Apply</p>
            </div>
            {/* Subscription Payment Card */}
            <div
              className={`payment ${
                selectedCard === "subscription" ? "subscription" : ""
              }`}
              onClick={() => setSelectedCard("subscription")}
            >
              <p className="card-title">Subscription</p>
              <p className="subscription">
                Service is billed monthly or yearly Terms Apply
              </p>
            </div>
          </div>
          <div className="product">Product Cost</div>
          <input
            type="text"
            className="edit-cost"
            value={editProductCost}
            onChange={(e) => setEditProductCost(e.target.value)}
          />
          {/* Conditional Rendering Based on Selected Payment Option */}
          {selectedCard === "oneTimePayment" ? (
            <div className="product">One Time Purchase</div>
          ) : (
            <>
              <p className="product">Monthly Subscription Price</p>
              <input
                type="text"
                className="edit-cost"
                value={editMonthlySubscriptionPrice}
                onChange={(e) =>
                  setEditMonthlySubscriptionPrice(e.target.value)
                }
              />
              <p className="product">Yearly Subscription Price</p>
              <div className="toggle">
                <Switch
                  sx={{
                    transform: "scale(1.5)",
                    "& .MuiSwitch-thumb": {
                      width: 18,
                      height: 18,
                    },
                    "& .MuiSwitch-track": {
                      height: 14,
                    },
                  }}
                />
                <p>Set Yearly Subcription at 20% Discount of Monthly</p>
              </div>
            </>
          )}

          <label className="review">Review Terms</label>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outlined"
            sx={{
              textTransform: "none",
              backgroundColor: "none",
              color: "#00639A",
              borderRadius: "25px",
              padding: "8px 24px",
              "&:hover": { backgroundColor: "#f1faff" },
            }}
            onClick={toggle}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              backgroundColor: "#00639A",
              color: "white",
              borderRadius: "25px",
              padding: "8px 24px",
              "&:hover": { backgroundColor: "#347ba1" },
            }}
            onClick={handleSave}
          >
            Next
          </Button>
          {/* <HgButtonComponent
            buttonText={"NEXT"}
            isPrimary={true}
            onclickHandler={handleSave}
          /> */}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ProductPricingComponent;
