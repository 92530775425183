import React, { useState, useEffect } from "react";
import "../../styles/searchBarComponent.scss";
import { ImgSearch } from "../../images";
import { useDispatch, useSelector } from "react-redux";
import { setOceanDataFilter, getOceanFilterDataset } from "../../actions/datasetActions";
import PillButton from "./pillButton";

const SearchBarComponent = ({ searchText, setSearchText }) => {
  const [inputValue, setInputValue] = useState("");
  const [keywords, setKeywords] = useState([]);
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const { filterObj } = useSelector((state) => ({
    filterObj: state.dataset.oceanDataFilter
  }))

  useEffect(() => {
    if (searchText) {
      const initialKeywords = searchText.split(",").filter((k) => k.trim() !== "");
      setKeywords(initialKeywords);
    } else {
      setKeywords([]);
    }
  }, [searchText]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim()) {
      const newKeyword = [...keywords, inputValue.trim()];
      setKeywords(newKeyword);
      setSearchText(newKeyword.join(","));
      setInputValue("");
    }
  };

  const removeKeyword = (keyword) => {
    const updatedKeywords = keywords.filter((k) => k.trim() !== keyword.trim());
    setKeywords(updatedKeywords);

    const updatedFilterObj = {
      ...filterObj,
      search_keywords: updatedKeywords,
    };
    setSearchText(updatedKeywords.join(",").trim());
    dispatch(setOceanDataFilter(updatedFilterObj));
  };

  return (
    <div className="search-bar">
      <div className="pill-list">
        {keywords.map((keyword, index) => (
          <PillButton
            key={index}
            keyword={ keyword }
            removeKeyword={() => removeKeyword(keyword)}
          />
        ))}
        <input
          type="text"
          placeholder="Search"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
      </div>
      <img className="search-icon" src={ImgSearch} />
    </div>
  );
};

export default SearchBarComponent;
