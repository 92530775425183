import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const TOAST_TYPE = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  INFO: "INFO",
  WARNING: "WARNING",
};

// configuring the toast notification message base on the severity
const showToast = (message, type) => {
  const config = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };

  switch (type) {
    case TOAST_TYPE.SUCCESS:
      toast.success(message, config);
      break;
    case TOAST_TYPE.ERROR:
      toast.error(message, config);
      break;
    case TOAST_TYPE.INFO:
      toast.info(message, config);
      break;
    case TOAST_TYPE.WARNING:
      toast.warning(message, config);
      break;
    default:
      toast(message, config);
  }
};

export default showToast;
