import React, { useState, useEffect } from "react";
import axios from "axios";
import sessionUtils from "../utils/sessionUtils";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import '../../styles/modalComponent.scss';
import "../styles/datasetNameEditModal.scss";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import { formatDate } from "../utils/utils";
const { REACT_APP_API_URL } = process.env;
export const API_URL = REACT_APP_API_URL;

const CustomTextField = styled(TextField)({
  marginLeft: "50px",
  display: "flex",
  width: "610px",
  gap: "10px",
  alignSelf: "stretch",
  borderRadius: "8px",
  border: "1px dashed #51606F",
  backgroundColor: "#FCFCFF",
  boxShadow:
    "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
  "& .MuiInputBase-root": {
    height: "auto", // Allow the root to auto-grow based on content
    padding: "0",
  },
  "& .MuiInputBase-input": {
    padding: "8px 15px",
    fontFamily: "SpaceGrotesk",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
  },
  // Ensure the TextField behaves as a multiline input
  "& .MuiInputBase-inputMultiline": {
    resize: "none", // Prevent manual resizing by the user
  },
  "& .MuiOutlinedInput-root": {
    minHeight: "60px",
    padding: "0", // Set a minimum height so it starts with enough space
  },
});

function DatasetNameEditPopupComponent(props) {
  const {
    toggle,
    modal,
    onAccept,
    name,
    dataset_root_uid,
    dataset_child_uid,
    onSave,
    creation,
  } = props;
  const [editableName, setEditableName] = useState(name || "");

  useEffect(() => {
    setEditableName(name || "");
  }, [name]);

  const handleNameChange = (event) => {
    setEditableName(event.target.value); // Update local state with new input
  };

  const handleCancel = () => {
    toggle();
  };

  const handleSave = async () => {
    const requestBody = {
      root_uid: dataset_root_uid,
      name: editableName,
    };

    const token = sessionUtils.getToken("access_token");

    const headers = {
      Authorization: `Bearer ${token}`, // Replace with your actual token
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.put(
        `${API_URL}/auth/v1/edit-dataset-name/`,
        requestBody,
        { headers }
      );
      if (response.status === 200) {
        onSave(editableName);
        onAccept(); // Trigger callback if provided

        // toggle(); // Close the modal
      }
    } catch (error) {
      console.error("Error updating description:", error);
      alert("Failed to update description. Please try again.");
    }
  };

  return (
    <div className="view-dataset-name-edit-description-component23">
      <Modal
        style={{ width: "800px" }}
        isOpen={modal}
        size={"md"}
        className={"view-dataset-name-edit-description-component23"}
        {...props}
      >
        <ModalHeader>Edit Dataset Title</ModalHeader>
        <ModalBody>
          <div
            className="content"
            style={{ display: "flex", flexDirection: "row", gap: "110px" }}
          >
            <div className="content-2">
              <div className="label-text-2">Present Title</div>
              <div className="div-wrapper">
                <p className="input-text-2">{name}</p>
              </div>
            </div>

            <div className="content-2">
              <div className="label-text-2">Uploaded</div>
              <div className="div-wrapper">
                <p className="input-text-2"> {formatDate(creation)}</p>
              </div>
            </div>
          </div>

          <div className="div-wrapper100">
            <div className="label-text-201">Edit Name</div>
          </div>
          <div
            style={{
              marginTop: "12px",
              display: "flex",
              flexDirection: "row",
              gap: "100px",
            }}
          >
            <CustomTextField
              value={editableName}
              onChange={handleNameChange}
              multiline
              // onChange={this.handleAttribution}
            />
          </div>

          {/* <div style={{wordWrap:'break-word', marginLeft:'46px'}} className='pd-8'><SafeHtmlComponent data={description} /></div> */}
        </ModalBody>
        <ModalFooter>
          <div className="togglebtn">
            <Button
              variant="text"
              sx={{ borderRadius: "30px", padding: "5px 16px" }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            {/* <div
              style={{
                fontFamily: "Roboto",
                color: "#82A6D9",
                width: "84px",
                padding: "6px 12px",
                background: "#FFFFFF",
                height: "36px",
                marginTop: "16px",
                marginRight: "-32px",
                cursor: "pointer",
              }}
              variant=""
              onClick={handleCancel}
            >
              Cancel
            </div> */}
            {/* <HgButtonComponent buttonText={"Close"} isPrimary={false} onclickHandler={() => onAccept()} /> */}
            <Button
              sx={{
                fontFamily: "Roboto",
                border: "1px solid #82A6D9",
                borderRadius: "30px",
                color: "#FFFFFF",
                width: "84px",
                padding: "5px 16px",
                background: "#00639A",
                height: "36px",
                marginTop: "16px",
                marginRight: "4px",
                "&:hover": { backgroundColor: "#347ba1" },
              }}
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default DatasetNameEditPopupComponent;
