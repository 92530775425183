import React, { useState, useEffect } from "react";
import axios from "axios";
import sessionUtils from "../../utils/sessionUtils";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import '../../styles/modalComponent.scss';
import "../../styles/datasetDescriptionEditModal.scss";
import { getVersionString } from "../../utils/utils";
import DatasetPreviewComponent from "./datasetPreviewComponent";
import HgButtonComponent from "./hgButtonComponent";
import SafeHtmlComponent from "./safeHtmlComponent";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import Switch from "@mui/material/Switch";
const { REACT_APP_API_URL } = process.env;
export const API_URL = REACT_APP_API_URL;

const CustomTextField = styled(TextField)({
    marginLeft: '50px',
    display: "flex",
    width: "100%",
    gap: "10px",
    alignSelf: "stretch",
    borderRadius: "8px",
    border: "1px dashed #51606F",
    backgroundColor: "#FCFCFF",
    boxShadow:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
    "& .MuiInputBase-root": {
      height: "auto", // Allow the root to auto-grow based on content
      padding: "10px 15px",
      alignItems: "start",
    },
    "& .MuiInputBase-input": {
      padding: "0",
      fontFamily: "SpaceGrotesk",
      fontSize: "14px",
      fontWeight: 500,
      textAlign: "start",
    },
    // Ensure the TextField behaves as a multiline input
    "& .MuiInputBase-inputMultiline": {
      resize: "none", // Prevent manual resizing by the user
      padding: "0", // Ensure text starts at the top
      verticalAlign: "top", // Align text to the top
    },
    "& .MuiOutlinedInput-root": {
      minHeight: "100px", // Set a minimum height so it starts with enough space
      display: "flex",
      alignItems: "start", // Align text to the top
    },
});

const label = { inputProps: { "aria-label": "Switch demo" } };

function DatasetDescriptionEditPopupComponent(props) {
  const {
    description,
    toggle,
    modal,
    onAccept,
    name,
    version,
    dataset_root_uid,
    dataset_child_uid,
    onSave,
  } = props;
  const [editableDescription, setEditableDescription] = useState(
    description || ""
  );
  const [additionalNotes, setAdditionalNotes] = useState("");

  useEffect(() => {
    setEditableDescription(description || "");
  }, [description]);

  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = (event) => {
    setIsToggled(event.target.checked);
  };

  const handleDescriptionChange = (event) => {
    setEditableDescription(event.target.value); // Update local state with new input
  };

  const handleAdditionalNotesChange = (event) => {
    setAdditionalNotes(event.target.value);
  };

  const handleSave = async () => {
    const requestBody = {
      dataset_root_uid: dataset_root_uid,
      dataset_child_uid: dataset_child_uid,
      description: editableDescription,
      apply_to_all_versions: isToggled,
      additional_version_notes: additionalNotes,
    };

    const token = sessionUtils.getToken("access_token");

    const headers = {
      Authorization: `Bearer ${token}`, // Replace with your actual token
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.put(
        `${API_URL}/auth/v1/update-dataset/description/`,
        requestBody,
        { headers }
      );
      if (response.status === 200) {
        onSave(editableDescription, additionalNotes, isToggled);
        onAccept(); // Trigger callback if provided

        // toggle(); // Close the modal
      }
    } catch (error) {
      console.error("Error updating description:", error);
      alert("Failed to update description. Please try again.");
    }
  };

  console.log("toggle again called 1");
  return (
    <div className="view-dataset-edit-description-component">
      <Modal
        style={{ width: "800px" }}
        isOpen={modal}
        toggle={toggle}
        size={"md"}
        className={"view-dataset-edit-description-component"}
        {...props}
      >
        <ModalHeader toggle={toggle}>Edit Description</ModalHeader>
        <ModalBody>
          <div className="content">
            <div className="label-text-2">Dataset Title</div>
            <div className="div-wrapper">
              <p className="input-text-2">{name}</p>
            </div>
          </div>

          <div className="content">
            <div className="label-text-2">Dataset Version</div>
            <div className="div-wrapper">
              <p className="input-text-2">{version}</p>
            </div>
          </div>

          <div className="content">
            <div className="label-text-3">Dataset Description</div>
          </div>

          <div className="div-wrapper100">
            <div className="label-text-201">Description</div>
          </div>
          <div style={{ marginTop: "12px" }}>
            <CustomTextField
              value={editableDescription}
              onChange={handleDescriptionChange}
              multiline
              inputProps={{ "aria-label": "Description" }}
              // onChange={this.handleAttribution}
            />
          </div>

          <div className="div-wrapper100">
            <div className="label-text-201">Additional Version Notes</div>
          </div>
          <div style={{ marginTop: "12px" }}>
            <CustomTextField
              value={additionalNotes}
              onChange={handleAdditionalNotesChange}
              inputProps={{ "aria-label": "Additional Version Notes" }}
            />
          </div>
          {/* <div style={{wordWrap:'break-word', marginLeft:'46px'}} className='pd-8'><SafeHtmlComponent data={description} /></div> */}
        </ModalBody>
        <ModalFooter>
          <div className="togglebtn">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Switch
                checked={isToggled}
                onChange={handleToggle}
                color="primary" // 'primary', 'secondary', or 'default'
              />
              <div variant="body1">
                {isToggled
                  ? "Changes applied to all the versions"
                  : "Apply changes to all versions"}
              </div>
            </div>
            {/* <HgButtonComponent buttonText={"Close"} isPrimary={false} onclickHandler={() => onAccept()} /> */}
            <Button
              className="btnspecial"
              variant="contained"
              onClick={handleSave}
            >
              SAVE
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default DatasetDescriptionEditPopupComponent;
