import { DATASET_ACTIONS } from "../actions/actionTypes";
import {
  DATASET_OBJECT,
  DATASET_STATUS,
  ROOT_DATASET_API_RESPONSE_OBJ,
} from "../config";

const dataset_object = DATASET_OBJECT;
const initialState = {
  isLoading: false,
  isDatasetsListLoading: false,
  rootDatasets: { ...ROOT_DATASET_API_RESPONSE_OBJ },
  childDatasets: [],
  datasetInfo: { ...dataset_object },
  datasetSharedWithUsers: [],
  oceanDatasets: { ...ROOT_DATASET_API_RESPONSE_OBJ },
  oceanDataFilter: {},
  error: null,
};

const updateRootDatasets = ({ datasetIds, status }, rootDatasets) => {
  let results = [];
  switch (status) {
    case DATASET_STATUS.DELETED:
      results = rootDatasets.results;
      for (let datasetId of datasetIds) {
        results = results.filter((item) => {
          if (item.uid !== datasetId) {
            console.log(item.uuid, datasetId);
            return item;
          }
        });
      }
      rootDatasets.results = results;
      return rootDatasets;
    default:
      results = rootDatasets.results.map((item) => {
        for (let datasetId of datasetIds) {
          if (item.uid === datasetId) {
            item.status = status;
            return item;
          }
        }
        return item;
        // if (item.uid === datasetId) {
        //   item.status = status;
        //   return item;
        // }
        // return item;
      });
      rootDatasets.results = results;
      return rootDatasets;
  }
};

const updateDatasetInfo = (datasetInfo, childDataset) => {
  console.log({ ...datasetInfo, latest_version: childDataset });
  return { ...datasetInfo, latest_version: childDataset };
};

export default function datasetReducer(state = initialState, action) {
  switch (action.type) {
    case DATASET_ACTIONS.ROOT_DATASETS_LOADING:
      return { ...state, isDatasetsListLoading: true };
    case DATASET_ACTIONS.ROOT_DATASETS_SUCCESS:
      return {
        ...state,
        isDatasetsListLoading: false,
        rootDatasets: { ...action.payload },
      };
    case DATASET_ACTIONS.ROOT_DATASETS_ERROR:
      return { ...state, isDatasetsListLoading: false };
    case DATASET_ACTIONS.ROOT_DATASET_INFO_LOADING:
      return { ...state, isDatasetsListLoading: true };
    case DATASET_ACTIONS.ROOT_DATASET_INFO_SUCCESS:
      return {
        ...state,
        isDatasetsListLoading: false,
        datasetInfo: { ...action.payload },
      };
    case DATASET_ACTIONS.ROOT_DATASET_INFO_ERROR:
      return { ...state, isDatasetsListLoading: false };
    case DATASET_ACTIONS.ADD_DATASET_LOADING:
      return { ...state, isLoading: true };
    case DATASET_ACTIONS.ADD_DATASET_SUCCESS:
      return { ...state, isLoading: false, datasetInfo: { ...action.payload.data } };
    case DATASET_ACTIONS.ADD_DATASET_ERROR:
      return { ...state, isLoading: false };
    case DATASET_ACTIONS.UPDATE_DATASET_LOADING:
      return { ...state, isLoading: true };
    case DATASET_ACTIONS.UPDATE_DATASET_SUCCESS:
      return { ...state, isLoading: false };
    case DATASET_ACTIONS.UPDATE_DATASET_ERROR:
      return { ...state, isLoading: false };
    case DATASET_ACTIONS.UDPATE_DATASET_STATUS_LOADING:
      return { ...state, isLoading: true };
    case DATASET_ACTIONS.UDPATE_DATASET_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rootDatasets: updateRootDatasets(action.payload, state.rootDatasets),
      };
    case DATASET_ACTIONS.UDPATE_DATASET_STATUS_ERROR:
      return { ...state, isLoading: false };
    case DATASET_ACTIONS.SHARE_DATASET_LOADING:
      return { ...state, isLoading: true };
    case DATASET_ACTIONS.SHARE_DATASET_SUCCESS:
      return { ...state, isLoading: false };
    case DATASET_ACTIONS.SHARE_DATASET_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    case DATASET_ACTIONS.CLEAR_SHARE_DATASET_ERROR:
      return { ...state, error: null };      
    case DATASET_ACTIONS.DATASET_VERSIONS_LOADING:
      return { ...state, isLoading: true };
    case DATASET_ACTIONS.DATASET_VERSIONS_SUCCESS:
      return { ...state, isLoading: false, childDatasets: action.payload };
    case DATASET_ACTIONS.DATASET_VERSIONS_ERROR:
      return { ...state, isLoading: false };
    case DATASET_ACTIONS.DATASET_VERSION_INFO_LOADING:
      return { ...state, isLoading: true };
    case DATASET_ACTIONS.DATASET_VERSION_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        datasetInfo: updateDatasetInfo(state.datasetInfo, action.payload),
      };
    case DATASET_ACTIONS.DATASET_VERSION_INFO_ERROR:
      return { ...state, isLoading: false };
    case DATASET_ACTIONS.DATASET_DOWNLOAD_LOADING:
      return { ...state, isLoading: true };
    case DATASET_ACTIONS.DATASET_DOWNLOAD_SUCCESS:
      return { ...state, isLoading: false };
    case DATASET_ACTIONS.DATASET_DOWNLOAD_ERROR:
      return { ...state, isLoading: false };
    case DATASET_ACTIONS.DATASET_SHARED_USERS_LOADING:
      return { ...state, isLoading: true };
    case DATASET_ACTIONS.DATASET_SHARED_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        datasetSharedWithUsers: [...action.payload],
      };
    case DATASET_ACTIONS.DATASET_SHARED_USERS_ERROR:
      return { ...state, isLoading: false };
    case DATASET_ACTIONS.DATASET_SHARE_ENABLE_DISABLE_LOADING:
      return { ...state, isLoading: true };
    case DATASET_ACTIONS.DATASET_SHARE_ENABLE_DISABLE_SUCCESS:
      return { ...state, isLoading: false };
    case DATASET_ACTIONS.DATASET_SHARE_ENABLE_DISABLE_ERROR:
      return { ...state, isLoading: false };
    case DATASET_ACTIONS.ADD_DATASET_VERSION_LOADING:
      return { ...state, isLoading: true };
    case DATASET_ACTIONS.ADD_DATASET_VERSION_SUCCESS:
      return { ...state, isLoading: false };
    case DATASET_ACTIONS.ADD_DATASET_VERSION_ERROR:
      return { ...state, isLoading: false };
    case DATASET_ACTIONS.OCEAN_DATASETS_LOADING:
      return { ...state, isDatasetsListLoading: true };
    case DATASET_ACTIONS.OCEAN_DATASET_LIST_SUCCESS:
      return {
        ...state,
        isDatasetsListLoading: false,
        oceanDatasets: { ...action.payload },
      };
    case DATASET_ACTIONS.OCEAN_DATASET_LIST_ERROR:
      return {
        ...state,
        isDatasetsListLoading: false,
      };
    case DATASET_ACTIONS.OCEAN_DATA_SET_FILTER:
      return { ...state, oceanDataFilter: { ...action.payload } };
    case DATASET_ACTIONS.OCEAN_FILTER_DATASETS_LOADING:
      return { ...state, isDatasetsListLoading: true };
    case DATASET_ACTIONS.OCEAN_FILTER_DATASETS_SUCCESS:
      return {
        ...state,
        isDatasetsListLoading: false,
        oceanDatasets: { ...action.payload },
      };
    case DATASET_ACTIONS.UPDATE_DATASET_ACCESS_TYPE:
      return { ...state, isLoading: true };
    case DATASET_ACTIONS.UPDATE_DATASET_ACCESS_TYPE_SUCCESS:
      const {email, dataset_id, access_type} = action.payload;
      const updateDatasetSharedWithUsers = state.datasetSharedWithUsers.map(
        (user) =>
          user.email === email 
          ? {...user, access_type} : user
      );
      return {
        ...state,
        isLoading: false,
        datasetSharedWithUsers: updateDatasetSharedWithUsers
      }
    case DATASET_ACTIONS.UPDATE_DATASET_ACCESS_TYPE_ERROR:
      return { ...state, isLoading: false };
    case DATASET_ACTIONS.REMOVE_ACCESS_TYPE_LOADING:
      return { ...state, isLoading: true};
    case DATASET_ACTIONS.REMOVE_ACCESS_TYPE_SUCCESS:
      console.log(action.payload);
      const updatedSharedDatasetUsers = state.datasetSharedWithUsers.filter(
        (user) => user.email !== action.payload.email
      );

      console.log(updatedSharedDatasetUsers)
      return { 
        ...state, 
        isLoading: false, 
        datasetSharedWithUsers: updatedSharedDatasetUsers
      }
    case DATASET_ACTIONS.REMOVE_ACCESS_TYPE_ERROR:
      return { ...state, isLoading: false};
    default:
      return state;
  }
}
