import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "../../styles/table.scss";
import { FiArrowUp } from "react-icons/fi";
import Loader from "../loaderComponent";

function Table({ tableHeader, tableData, isLoading }) {
  const history = useHistory();

  const handlePublishedDatasetDetails = (uid) => {
    history.push(`/published-datasets/${uid}`);
  };
  // handle the sorting for the columns  alhabetically
  return (
    <>
      <table className="table-container">
        <thead>
          <tr>
            {tableHeader.map((header) => (
              <th
                key={header.lable}
                // onClick={() => header.sortable && alert("Sorting function")}
              >
                <span style={{ cursor: `${header.sortable && "pointer"}` }}>
                  {header.lable} {header.sortable && <FiArrowUp color="gray" />}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <td colSpan={tableHeader.length}>
              <div className="loader-container">
                <Loader />
              </div>
            </td>
          ) : (
            <>
              {tableData?.map((data, index) => (
                <tr className="table-data" key={index}>
                  <td onClick={() => handlePublishedDatasetDetails(data.uid)}>
                    {data.title}
                  </td>
                  <td onClick={() => handlePublishedDatasetDetails(data.uid)}>
                    {data.status}
                  </td>
                  <td onClick={() => handlePublishedDatasetDetails(data.uid)}>
                    {data.owner || "NA"}
                  </td>
                  <td onClick={() => handlePublishedDatasetDetails(data.uid)}>
                    {data.price}
                  </td>
                  {/* convert the date into MM/DD/YY */}
                  <td onClick={() => handlePublishedDatasetDetails(data.uid)}>
                    {new Date(data.last_modified_date).toLocaleDateString(
                      "en-US"
                    )}
                  </td>
                  <td>
                    <button
                      className="action-btn"
                      onClick={() => handlePublishedDatasetDetails(data.uid)}
                    >
                      Edit
                    </button>
                    <button className="action-btn">Remove</button>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </>
  );
}

export default Table;
