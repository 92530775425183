import { useEffect, useLayoutEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { OPEN_SOURCE_LICENSE_LIST, SIDEBAR_TABS } from "../config";
import { ImgCrossRounded, ImgTBBCLogo } from "../images";
import "../styles/productListingComponent.scss";
import ImgTitleComponent from "./ImgTitleComponent";
import DatasetPreviewComponent from "./pure/datasetPreviewComponent";
import GridViewDatasetComponent from "./pure/gridViewDatasetComponent";
import SidebarComponent from "./pure/sidebarComponent";
import ShowMoreButton from "./showMoreButton";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import LLicenseComponent from "./licenseComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  getRootDatasetInfo,
  getRootDatasetsList,
} from "../actions/datasetActions";
import axios from "axios";
import styled from "styled-components";
import ListViewDatasetComponent from "./pure/listViewDatasetComponent";
import { GoChevronRight } from "react-icons/go";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import AddSampleDatasetPopup from "./pure/addSampleDatasetPopup";
import { getPublishedDatasetDetails } from "../actions/publishActions";
import Loader from "./loaderComponent";

const infoDummyTagList = [
  "Measurement Data",
  "Data Integration",
  "Climate Chnage",
  "NOAA",
  "Weather",
  "Event-Based Data",
  "Data-Analytics",
];

const GnuCardComponent = ({ toggle, licenseName }) => {
  return (
    <div className="gnu-card">
      <div className="top-section">
        <p className="gnu-title">{licenseName}</p>
        <button className="license-text" onClick={toggle}>
          SEE LICENSE & TERMS
        </button>
      </div>
      <div className="lower-section">
        <div className="add-to-datasets-text">Add to Your Datasets</div>
      </div>
    </div>
  );
};

const ProductListingComponent = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const history = useHistory();

  const [preview_table_header, setPreview_table_header] = useState([]);
  const [preview_table_data, setPreview_table_data] = useState([]);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [myDatasets, setMyDatasets] = useState([]);
  const [isListView, setIsListView] = useState(false);
  const dispatch = useDispatch();
  // extracting the uid of dataset
  const { rootDatasetId, publishDatasetId } = useParams();
  const location = useLocation();

  // check if we are on the "product-preview" route
  const isProductPreview = location.pathname.includes("/product-preview");
  const [openAddSampleModal, setOpenAddSampleModal] = useState(false);
  const { previewDetails } = useSelector((state) => {
    return {
      previewDetails: state.publish.publishedDatasetDetails?.data?.data,
    };
  });

  useEffect(() => {
    if (isProductPreview) {
      dispatch(
        getRootDatasetInfo(rootDatasetId, (previewUrl, Schema_Url) => {
          getPreview(previewUrl);
          getDatasetSchema(Schema_Url);
        })
      );
    } else if (publishDatasetId) {
      dispatch(getPublishedDatasetDetails(publishDatasetId));
    }
  }, []);

  useEffect(() => {
    if (previewDetails?.root_dataset_id) {
      dispatch(
        getRootDatasetInfo(
          previewDetails?.root_dataset_id,
          (previewUrl, Schema_Url) => {
            getPreview(previewUrl);
            getDatasetSchema(Schema_Url);
          }
        )
      );
    }
  }, [previewDetails]);
  useLayoutEffect(() => {
    // resetting the scroll position to top on every route change
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [history.location.pathname]);

  const getPreview = (url) => {
    if (url) {
      // this.setState({ isPreviewLoading: true });
      axios
        .get(url)
        .then(({ data }) => {
          setPreview_table_header(Object.keys(data[0]));
          setPreview_table_data([...data]);
          // this.setState({ preview_table_header: Object.keys(data[0]), preview_table_data: [...data] });
        })
        .catch((error) => {
          setPreview_table_header(Object.keys([]));
          setPreview_table_data([]);
          // this.setState({ preview_table_header: [], preview_table_data: [] });
        })
        .finally(() => {
          setIsPreviewLoading(false);
          // this.setState({ isPreviewLoading: false });
        });
    } else {
    }
  };

  const toggleDescription = () => {
    const ele = document.getElementsByClassName(
      "product-more-dataset-description"
    )[0];
    ele.classList.toggle("product-show-more");
  };

  const getDatasetSchema = (url) => {
    if (url) {
      this.setState({ isColumnDetailsLoading: true });
      axios
        .get(url)
        .then(({ data }) => {
          this.setState({ datasetSchema: data });
        })
        .catch((error) => {
          this.setState({ datasetSchema: [] });
        })
        .finally(() => {
          this.setState({ isColumnDetailsLoading: false });
        });
    } else {
    }
  };

  // handle when add sample dataset modal will be open
  const handleSampleDataset = () => {
    if (isProductPreview) {
      setOpenAddSampleModal(true);
    }
  };

  // handle when add sample dataset modal will be close
  const handleCloseModal = () => {
    setOpenAddSampleModal(false);
  };

  if (!previewDetails) {
    return <Loader />;
  }
  return (
    <>
      <div className="product-header-div">
        <img src={ImgTBBCLogo} />
      </div>
      <div className="w-100 d-flex">
        <div className="product-sidebar-section">
          <SidebarComponent
            activeTab={
              isProductPreview
                ? SIDEBAR_TABS.publishingManager
                : SIDEBAR_TABS.oceanDatasets
            }
          />
        </div>
        <div className="product-main-content">
          <div className="product-title-section">
            Ocean of Data /{" "}
            {isProductPreview ? (
              <>Publishing Manager/ Product Preview</>
            ) : (
              <>Measurement Data/ Institutional Data/ Climate Change</>
            )}
          </div>
          <div className="product-lower-main">
            <div className="product-lower-left-main">
              <ImgTitleComponent datasetName={previewDetails?.title} />
              <div className="product-dataset-info">
                <p className="product-dataset-title">Dataset Description</p>
                <p className="product-dataset-description">
                  {previewDetails?.description.slice(0, 191)}
                  <span className="product-more-dataset-description">
                    {previewDetails?.description.slice(191)}
                  </span>
                </p>
                {previewDetails?.description.length >= 190 && (
                  <ShowMoreButton
                    toggle={toggleDescription}
                    content="Show More"
                  />
                )}
                <div className="small-seperation-line"></div>
              </div>
              <div className="product-dataset-info">
                <p className="product-dataset-title">Information Categories</p>
                <div className="product-information-categories-tags-container">
                  {/* parsing back to object */}
                  {previewDetails?.tags &&
                    previewDetails?.tags.map((item) => {
                      if (typeof item === "string") {
                        item = item.replace(/'/g, '"');
                        item = JSON.parse(item);
                      }
                      return (
                        <span key={item.id} className="product-tag-item m-1">
                          {item?.metadata?.Variable_Level_1}
                        </span>
                      );
                    })}
                </div>
                {previewDetails?.tags && previewDetails?.tags.length > 5 && (
                  <ShowMoreButton content="Show More" />
                )}
                <div className="small-seperation-line"></div>
              </div>

              <div className="product-dataset-info license">
                <div className="d-flex justify-content-between">
                  <span>
                    <p className="product-dataset-title">License</p>
                    <p className="">
                      {OPEN_SOURCE_LICENSE_LIST.find(
                        (item) => item.value === previewDetails?.license
                      )
                        ? "Open source license"
                        : "Proprietary or Commercial License"}
                    </p>
                  </span>
                  <div className="product-view-license-button-container">
                    <ShowMoreButton toggle={toggle} content="View License" />
                  </div>
                </div>
                <div className="small-seperation-line"></div>
              </div>

              <div className="product-dataset-info">
                <p className="product-dataset-title">Dataset Overview</p>
                <div className="product-cards-description">
                  <div className="col-3 product-card">
                    <p className="title">DATA TYPE</p>
                    <p className="desc">{previewDetails?.dataset_format}</p>
                  </div>
                  <div className="mini-card product-card">
                    <p className="title">SIZE</p>
                    <p className="desc">{`${Math.ceil(
                      previewDetails?.dataset_size / 1024
                    )} MB`}</p>
                  </div>
                  <div className="mini-card product-card">
                    <p className="title">ROWS</p>
                    <p className="desc">
                      {previewDetails?.dataset_records_count}
                    </p>
                  </div>
                  <div className="mini-card product-card">
                    <p className="title">COLUMNS</p>
                    <p className="desc">
                      {previewDetails?.dataset_columns_count}
                    </p>
                  </div>
                  <div className="mini-card product-card">
                    <p className="title">CREATED</p>
                    <p className="desc">
                      {new Date(
                        previewDetails?.creation_date
                      ).toLocaleDateString("en-US")}
                    </p>
                  </div>
                  <div className="mini-card product-card">
                    <p className="title">UPDATED</p>
                    <p className="desc">
                      {new Date(
                        previewDetails?.last_modified_date
                      ).toLocaleDateString("en-US")}
                    </p>
                  </div>
                </div>
                <p className="product-dataset-title">Dataset Sample</p>
                <div className="product-preview-container mb-4">
                  <DatasetPreviewComponent
                    preview_table_data={preview_table_data}
                    preview_table_header={preview_table_header}
                  />
                </div>

                <div className="small-seperation-line"></div>
              </div>

              <div className="small-seperation-line"></div>
            </div>
            <div className="lower-right-main">
              <div className="gnu-public-license">
                <GnuCardComponent
                  toggle={toggle}
                  licenseName={previewDetails?.license}
                />
              </div>
              <div className="contact-section">
                <p>Help</p>
                <ul>
                  <li>Contact Sales</li>
                  <li>Documentation</li>
                  <li>Terms and Conditions</li>
                  <li>FAQ</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isProductPreview && (
        <div className="product-lower-container">
          <div className="product-datasets">
            <div className="product-heading">Popular</div>
            <div className="product-view-all">
              <div className="product-view-all-text">View All</div>
              <div className="product-next-icon">
                <GoChevronRight />
              </div>
            </div>
          </div>
          {!isListView ? (
            <div className="product-popular-datasets-grid">
              <GridViewDatasetComponent
                title={"Ballard_Weddell_Seal_Pup_tracks"}
                organization={"CCAMLR"}
                description={"Weddell sea pup track point data."}
                dataset_format={"Shapefile"}
                license={"Public"}
              />
              <GridViewDatasetComponent
                title={"Davis_Silverfish"}
                organization={"CCAMLR"}
                description={"Silverfish concentration (fishdensit) in increments like the other Davis datasets."}
                dataset_format={"Shapefile"}
                license={"Public"}
              />
              <GridViewDatasetComponent
                title={"Eco Fjord"}
                organization={"-"}
                description={"Physical oceanographic measurements from 2016 Eco Fjord cruise."}
                dataset_format={"CSV"}
                license={"Public"}
              />
            </div>
          ) : (
            <div className="product-popular-datasets-list">
              <ListViewDatasetComponent
                title={"Ballard_Weddell_Seal_Pup_tracks"}
                organization={"CCAMLR"}
                description={"Weddell sea pup track point data."}
                data_type={"Shapefile"}
                license={"Public"}
              />
              <ListViewDatasetComponent
                title={"Davis_Silverfish"}
                organization={"CCAMLR"}
                description={"Silverfish concentration (fishdensit) in increments like the other Davis datasets."}
                data_type={"Shapefile"}
                license={"Public"}
              />
              <ListViewDatasetComponent
                title={"Eco Fjord"}
                organization={"-"}
                description={"Physical oceanographic measurements from 2016 Eco Fjord cruise."}
                data_type={"CSV"}
                license={"Public"}
              />
            </div>
          )}
          {!isListView ? (
            <div className="product-popular-datasets-grid">
              <GridViewDatasetComponent
                title={"Ballard_Weddell_Seal_Pup_tracks"}
                organization={"CCAMLR"}
                description={"Weddell sea pup track point data."}
                dataset_format={"Shapefile"}
                license={"Public"}
              />
              <GridViewDatasetComponent
                title={"Davis_Silverfish"}
                organization={"CCAMLR"}
                description={"Silverfish concentration (fishdensit) in increments like the other Davis datasets."}
                dataset_format={"Shapefile"}
                license={"Public"}
              />
              <GridViewDatasetComponent
                title={"Eco Fjord"}
                organization={"-"}
                description={"Physical oceanographic measurements from 2016 Eco Fjord cruise."}
                dataset_format={"CSV"}
                license={"Public"}
              />
            </div>
          ) : (
            <div className="product-popular-datasets-list">
              <ListViewDatasetComponent
                title={"Ballard_Weddell_Seal_Pup_tracks"}
                organization={"CCAMLR"}
                description={"Weddell sea pup track point data."}
                data_type={"Shapefile"}
                license={"Public"}
              />
              <ListViewDatasetComponent
                title={"Davis_Silverfish"}
                organization={"CCAMLR"}
                description={"Silverfish concentration (fishdensit) in increments like the other Davis datasets."}
                data_type={"Shapefile"}
                license={"Public"}
              />
              <ListViewDatasetComponent
                title={"Eco Fjord"}
                organization={"-"}
                description={"Physical oceanographic measurements from 2016 Eco Fjord cruise."}
                data_type={"CSV"}
                license={"Public"}
              />
            </div>
          )}
          {!isListView && <div className="product-seperation-line"></div>}
          <div className="product-datasets">
            <div className="product-heading">All</div>
            <div className="product-view-all">
              <div className="product-view-all-text">View All</div>
              <div className="product-next-icon">
                <GoChevronRight />
              </div>
            </div>
          </div>
          {!isListView ? (
            <div className="product-popular-datasets-grid">
              <GridViewDatasetComponent
                title={"Ballard_Weddell_Seal_Pup_tracks"}
                organization={"CCAMLR"}
                description={"Weddell sea pup track point data."}
                dataset_format={"Shapefile"}
                license={"Public"}
              />
              <GridViewDatasetComponent
                title={"Davis_Silverfish"}
                organization={"CCAMLR"}
                description={"Silverfish concentration (fishdensit) in increments like the other Davis datasets."}
                dataset_format={"Shapefile"}
                license={"Public"}
              />
              <GridViewDatasetComponent
                title={"Eco Fjord"}
                organization={"-"}
                description={"Physical oceanographic measurements from 2016 Eco Fjord cruise."}
                dataset_format={"CSV"}
                license={"Public"}
              />
            </div>
          ) : (
            <div className="product-popular-datasets-list">
              <ListViewDatasetComponent
                title={"Ballard_Weddell_Seal_Pup_tracks"}
                organization={"CCAMLR"}
                description={"Weddell sea pup track point data."}
                data_type={"Shapefile"}
                license={"Public"}
              />
              <ListViewDatasetComponent
                title={"Davis_Silverfish"}
                organization={"CCAMLR"}
                description={"Silverfish concentration (fishdensit) in increments like the other Davis datasets."}
                data_type={"Shapefile"}
                license={"Public"}
              />
              <ListViewDatasetComponent
                title={"Eco Fjord"}
                organization={"-"}
                description={"Physical oceanographic measurements from 2016 Eco Fjord cruise."}
                data_type={"CSV"}
                license={"Public"}
              />
            </div>
          )}
          {!isListView ? (
            <div className="product-popular-datasets-grid">
              <GridViewDatasetComponent
                title={"Ballard_Weddell_Seal_Pup_tracks"}
                organization={"CCAMLR"}
                description={"Weddell sea pup track point data."}
                dataset_format={"Shapefile"}
                license={"Public"}
              />
              <GridViewDatasetComponent
                title={"Davis_Silverfish"}
                organization={"CCAMLR"}
                description={"Silverfish concentration (fishdensit) in increments like the other Davis datasets."}
                dataset_format={"Shapefile"}
                license={"Public"}
              />
              <GridViewDatasetComponent
                title={"Eco Fjord"}
                organization={"-"}
                description={"Physical oceanographic measurements from 2016 Eco Fjord cruise."}
                dataset_format={"CSV"}
                license={"Public"}
              />
            </div>
          ) : (
            <div className="product-popular-datasets-list">
              <ListViewDatasetComponent
                title={"Ballard_Weddell_Seal_Pup_tracks"}
                organization={"CCAMLR"}
                description={"Weddell sea pup track point data."}
                data_type={"Shapefile"}
                license={"Public"}
              />
              <ListViewDatasetComponent
                title={"Davis_Silverfish"}
                organization={"CCAMLR"}
                description={"Silverfish concentration (fishdensit) in increments like the other Davis datasets."}
                data_type={"Shapefile"}
                license={"Public"}
              />
              <ListViewDatasetComponent
                title={"Eco Fjord"}
                organization={"-"}
                description={"Physical oceanographic measurements from 2016 Eco Fjord cruise."}
                data_type={"CSV"}
                license={"Public"}
              />
            </div>
          )}
          {!isListView ? (
            <div className="product-popular-datasets-grid">
              <GridViewDatasetComponent
                title={"Ballard_Weddell_Seal_Pup_tracks"}
                organization={"CCAMLR"}
                description={"Weddell sea pup track point data."}
                dataset_format={"Shapefile"}
                license={"Public"}
              />
              <GridViewDatasetComponent
                title={"Davis_Silverfish"}
                organization={"CCAMLR"}
                description={"Silverfish concentration (fishdensit) in increments like the other Davis datasets."}
                dataset_format={"Shapefile"}
                license={"Public"}
              />
              <GridViewDatasetComponent
                title={"Eco Fjord"}
                organization={"-"}
                description={"Physical oceanographic measurements from 2016 Eco Fjord cruise."}
                dataset_format={"CSV"}
                license={"Public"}
              />
            </div>
          ) : (
            <div className="product-popular-datasets-list">
              <ListViewDatasetComponent
                title={"Ballard_Weddell_Seal_Pup_tracks"}
                organization={"CCAMLR"}
                description={"Weddell sea pup track point data."}
                data_type={"Shapefile"}
                license={"Public"}
              />
              <ListViewDatasetComponent
                title={"Davis_Silverfish"}
                organization={"CCAMLR"}
                description={"Silverfish concentration (fishdensit) in increments like the other Davis datasets."}
                data_type={"Shapefile"}
                license={"Public"}
              />
              <ListViewDatasetComponent
                title={"Eco Fjord"}
                organization={"-"}
                description={"Physical oceanographic measurements from 2016 Eco Fjord cruise."}
                data_type={"CSV"}
                license={"Public"}
              />
            </div>
          )}
        </div>
      )}

      <Modal
        isOpen={modal}
        toggle={toggle}
        style={{
          width: "540px",
          height: "fit-content",
        }}
        className={"modal-container"}
      >
        <ModalBody
          style={{
            width: "600px",
            height: "fit-content",
            margin: "0",
            display: "flex",
            flexDirection: "column",
            padding: "1em",
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.3)",
          }}
        >
          <div className="align-self-end">
            <button style={{ border: "none", padding: "0" }} onClick={toggle}>
              <img
                style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}
                src={ImgCrossRounded}
              />
            </button>
          </div>
          <LLicenseComponent />
        </ModalBody>
      </Modal>
    </>
  );
};

export default ProductListingComponent;
