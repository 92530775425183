import { API_URL, API_VERSION, STORAGE_KEY } from '../config';
import axios from 'axios';
import axiosInstance from './axiosConfig';
import SessionUtils from '../utils/sessionUtils';

/**
 * @class AccountApi
 */
class AccountApi {

    /**
     * Register user
     * @param {string} email 
     * @param {string} password1 
     * @param {string} password2 
     * @param {string} organisationName 
     * @param {string} username 
     * @returns 
     */
    static signup(first_name, last_name, email, password, confirm_password, country, country_code , organization, recaptcha, username = '') {
        const axiosBody = {
            // username, 
            email,
            password,
            confirm_password,
            first_name,
            last_name,
            country,
            country_code,
            organization
        }
        return axios.post(`${API_URL}/auth/user/register/`, axiosBody);
    }

    static resentVerifyEmail(email) {
        const axiosBody = {
            email
        }
        return axios.post(`${API_URL}/auth/user/register/resend-account-verification-email/`, axiosBody);
    }

    /**
     * Verify Email
     * @param {string} key 
     * @returns 
     */
    static verifyEmail(key) {
        return axios.get(`${API_URL}/auth/user/activate/?tk=${key}`);
    }

    static getResetPasswordLink(email) {
        const axiosBody = {
            email
        }
        return axios.post(`${API_URL}/auth/user/send-reset-password-email/`, axiosBody);
    }

    static setResetPassword(password, confirm_password, token) {
        const axiosBody = {
            password,
            confirm_password,
            token
        }
        return axios.post(`${API_URL}/auth/user/reset-password/`, axiosBody);
    }

    static getUserInfo() {
        return axiosInstance.get(`${API_URL}/auth/user/profile`);
    }

    static changePassword(current_password, password, confirm_password) {
        const axiosBody = {
            current_password,
            password,
            confirm_password
        }
        return axiosInstance.patch(`${API_URL}/auth/user/change-password/`, axiosBody);
    }

    static updateUserInfo(username, first_name, last_name,profile_pic, organization_name) {
        const axiosBody = {
            // username,
            // userprofile: {
                first_name,
                last_name,
                profile_pic,
                // organization_name
            // }
        }
        return axiosInstance.patch(`${API_URL}/auth/user/profile-update/`, axiosBody);
    }
  
}

export default AccountApi;
