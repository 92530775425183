import React, { useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import HgButtonComponent from "./hgButtonComponent";
import "../../styles/fileComponent.scss";
import fileImage from "../../images/FileLogo.png";
import { RiDeleteBin6Line } from "react-icons/ri";
import AuthorizationPopupComponent from "./authorizationPopupComponent";
import axios from "axios";
import sessionUtils from "../../utils/sessionUtils";
import { Button } from "@mui/material";
const { REACT_APP_API_URL } = process.env;
export const API_URL = REACT_APP_API_URL;

const AdditionalFileComponent = (props) => {
  const { download, is_shared, shared_users, secondary } = props;
  const manageAccess = shared_users;
  const fileInputRef = useRef();

  const [showPopup, setShowPopup] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const secondary_file = secondary?.file_name?.secondary_name;

  const [files, setFiles] = useState(secondary_file || []);

  //component to render the additional files

  const handleAuthorization = () => {
    if (is_shared && !manageAccess) {
      setShowPopup(true);
      console.log(showPopup);
      return;
    }
    triggerFilePicker();
  };

  const triggerFilePicker = () => {
    fileInputRef.current.click();
  };

  //function for deleting the selected dataset
  const handleDeleteAccess = (uid) => {
    if (is_shared && !manageAccess) {
      setShowPopup(true);
      return;
    }

    const token = sessionUtils.getToken("access_token");
    axios
      .delete(`${API_URL}/auth/v1/remove-additional-file/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: { data_uid: uid }, // Send the file's unique ID
      })
      .then((response) => {
        if (response.status === 200) {
          // Update state to remove deleted file
          setFiles((prevFiles) => prevFiles.filter((file) => file.uid !== uid));
          setIsDeleteModalOpen(false);
          setFileToDelete(null); // Clear the selected file
        } else {
          alert("Failed to delete file. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error deleting file:", error);
        alert("An error occurred. Please try again.");
      });
  };

  const confirmDelete = (file) => {
    setFileToDelete(file);
    setIsDeleteModalOpen(true); // Open delete modal
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setFileToDelete(null); // Reset file selection
  };

  return (
    <div
      style={{ flexDirection: "column", height: "auto", borderBottom: "none" }}
      className="file-component"
    >
      {files && files.length > 0 ? (
        files.map((file, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
              borderBottom: "1px solid #ddd",
              paddingBottom: "10px",
            }}
            className="file-row"
          >
            {/* Image for each file */}
            <img className="file-img" src={fileImage} alt="file" />

            {/* File name */}
            <div
              style={{ flexGrow: 1, marginLeft: "20px" }}
              className="file-name"
            >
              {file.file_name}
            </div>

            {/* Download button */}
            <a className="download-file" href={file.file_url} target="_self">
              Download
            </a>

            {/* Delete button */}
            <div className="delete-file">
              {!(is_shared && !manageAccess) && (
                <div
                  style={{
                    cursor: "pointer", // The icon is only rendered when clickable, so "pointer" is always fine here
                  }}
                  onClick={() => confirmDelete(file)} // Pass file uid or other unique identifier for delete
                >
                  <RiDeleteBin6Line />
                </div>
              )}
            </div>
          </div>
        ))
      ) : (
        <p>No secondary files available.</p>
      )}

      {/* Other components, e.g., file input or authorization popup */}
      <input type="file" ref={fileInputRef} style={{ display: "none" }} />

      {showPopup && (
        <AuthorizationPopupComponent
          showPopup={showPopup}
          onClose={() => setShowPopup(false)}
        />
      )}

      <Modal
        style={{
          width: "349px",
          top: "24px",
          left: "20px",
          padding: "16px",
        }}
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        className="share-dataset-component"
      >
        <ModalHeader
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "32px",
            fontSize: "11px",
            fontWeight: 500,
          }}
        >
          <span
            style={{
              flex: "2",
              fontSize: "11px",
              fontWeight: 500,
              lineHeight: "16px",
            }}
          >
            Delete Confirmation
          </span>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              margin: "20px 0",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#1A1C1E",
            }}
          >
            Are you sure you want to delete the selected dataset?
          </div>
          <div
            style={{
              fontSize: "11px",
              color: "#93000A",
              fontWeight: 500,
              marginBottom: "20px",
            }}
          >
            This action cannot be undone.
          </div>
        </ModalBody>

        <ModalFooter
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {/* <HgButtonComponent
            buttonText={"CANCEL"}
            isPrimary={false}
            onclickHandler={closeDeleteModal}
          /> */}
          <Button
            variant="text"
            onClick={closeDeleteModal}
            sx={{
              textTransform: "none",
              padding: "10px 20px",
              borderRadius: "30px",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="text"
            onClick={() => handleDeleteAccess(fileToDelete?.uid)}
            sx={{
              textTransform: "none",
              backgroundColor: "#00639A",
              color: "white",
              padding: "10px 20px",
              borderRadius: "30px",
              "&:hover": { backgroundColor: "#347ba1" },
            }}
          >
            Delete
          </Button>

          {/* <HgButtonComponent
            buttonText={"DELETE"}
            isPrimary={true}
            onclickHandler={() => handleDeleteAccess(fileToDelete?.uid)}
            type="submit"
          /> */}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AdditionalFileComponent;
