import React, { useRef, useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import '../../styles/modalComponent.scss';
import "../../styles/shareDatasetComponent.scss";
import "../../styles/form.scss";
import HgButtonComponent from "./hgButtonComponent";
import { DATASET_ACCESS_TYPE } from "../../config";
import HgDropdownComponent from "./hgDropdownComponent";
import { ImgAddBalck, ImgPlus } from "../../images";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearShareDatasetError } from "../../actions/datasetActions";

function ShareDatasetComponent(props) {
  const { rootDatasetId, toggle, modal, onAccept, onSubmit } = props;
  const formObject = { email: "", accessType: "", isValid: true };
  const [formElementsList, setFormElementsList] = useState([formObject]);
  const formRef = useRef();
  const dispatch = useDispatch();
  const errorMessage = useSelector((state)=> state.dataset?.error);
  const [userErrors, setUserErrors] = useState({});

  const onBlur = (e) => {};

  const onChange = (index, event) => {
    let data = [...formElementsList];
    data[index][event.target.name] = event.target.value;
    setFormElementsList(data);
  };

  const onAccessTypeSelect = (item, index) => {
    let data = [...formElementsList];
    data[index].accessType = item.value;
    setFormElementsList(data);
  };

   useEffect(() => {
      if(errorMessage){
        setUserErrors(errorMessage);
       
      } else {
        setUserErrors({});
      }
   }, [errorMessage])

  // clear error message and other feilds of the form after closing the modal
   useEffect(() => {
    if (!modal) {
      dispatch(clearShareDatasetError());
      setFormElementsList([{ email: "", accessType: "", isValid: true }]);
    }
  }, [modal]);  

  const fromElement = (input, index) => {
    const emailError = userErrors[index]?.email?.[0]; 
    const nonFieldError = userErrors[index]?.non_field_errors?.[0];
    const accessTypeError = userErrors[index]?.access_type?.[0];
    return (
      <>
        <div className="form-field">
          <div className="form-label">Share to</div>
          <input
            type="text"
            name="email"
            className="form-control"
            placeholder="receipient email"
            onBlur={(event) => onBlur(index, event)}
            onChange={(event) => onChange(index, event)}
            value={input.email}
          />
         {/* if both feilds are empty */}
          {emailError && <div className="error-message">Please enter a valid email</div>} 
          {/* if email is not valid */}
          {!emailError && nonFieldError && (
            <div className="error-message">Email not found</div>
          )}
          {/* if access type is empty */}
          {!emailError && accessTypeError && (
            <div className="error-message">Please choose a valid access type</div>
          )}
          {/* <span className="form-icon email"></span> */}
          {/* {!this.state.usernameValid && <FormValidationErrorContainer errMessage={this.state.usernameErrorMessage} />} */}
        </div>
        <div className="form-field drop-down">
          <div className="form-label">Access Type</div>
          <HgDropdownComponent
            listItems={DATASET_ACCESS_TYPE}
            placeholder={"Select"}
            onSelectHandler={(item) => onAccessTypeSelect(item, index)}
          />
        </div>
      </>
    );
  };

  const onAdditionalUsersHandler = () => {
    setFormElementsList([...formElementsList, formObject]);
  };

  const validateFormData = () => {
    const data = [...formElementsList];

    onSubmit(
      rootDatasetId,
      data.map((item) => {
        return {
          root_dataset_id: rootDatasetId,
          email: item.email,
          access_type: item.accessType,
          is_downloadable: true,
          is_paid: false,
        };
      })
    );
  };

  return (
    <div className="share-dataset-component">
      <Modal
        isOpen={modal}
        toggle={toggle}
        size={"md"}
        className={"share-dataset-component"}
        {...props}
      >
        <ModalHeader toggle={toggle}>Share Dataset</ModalHeader>
        <ModalBody>
          <div className="gen-info">
            Sharing dataset or adding user to the dataset is given access rights
            to the user.
          </div>
          <form className="dsg-form" noValidate={false} ref={formRef}>
            {formElementsList.map((item, index) => {
              return (
                <fieldset key={index}>{fromElement(item, index)}</fieldset>
              );
            })}
          </form>
          <div className="additional-users">
            Additional Users{" "}
            <img src={ImgAddBalck} onClick={onAdditionalUsersHandler} />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="text"
            sx={{
              textTransform: "none",
              padding: "10px 20px",
              borderRadius: "30px",
            }}
            onClick={() => onAccept()}
          >
            Cancel
          </Button>
          {/* <HgButtonComponent buttonText={"Cancel"} isPrimary={false} onclickHandler={() => onAccept()} /> */}
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              backgroundColor: "#00639A",
              color: "white",
              borderRadius: "25px",
              padding: "8px 24px",
              "&:hover": { backgroundColor: "#347ba1" },
            }}
            onClick={validateFormData}
          >
            Share
          </Button>
          {/* <HgButtonComponent buttonText={"Share"} isPrimary={true} onclickHandler={validateFormData} type="submit" /> */}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ShareDatasetComponent;
