import React, { useState } from "react";
import "../../styles/multiSelect.scss";
import PillButton from "./pillButton";
import { v4 as uuid } from "uuid";

const AttributionMultiSelect = ({
  selectedAttribution = [],
  setSelectedAttribution,
  setIsFormValid,
}) => {
  const [inputText, setInputText] = useState("");

  // Add new attribution
  const addNewAttribution = (e) => {
    if (e.keyCode === 13 || e.key === "Enter") {
      if (e.target.value.length >= 2) {
        let newAttribution = {
          id: uuid(),
          text: e.target.value, // Store the entered attribution
        };
        setSelectedAttribution((prevState) => ({
          ...prevState,
          selectedAttribution: [...selectedAttribution, newAttribution],
          isFormValid: true,
        }));
        setInputText("");
        // setIsFormValid(true);
      }
    }
  };

  // Remove selected attribution
  const removeAttribution = (selectedId, setIsFormValid) => {
    const updatedList = selectedAttribution.filter(
      (item) => item.id !== selectedId
    );
    setSelectedAttribution((prevState) => ({
      ...prevState,
      selectedAttribution: updatedList,
      isFormValid: false,
    }));
  };

  return (
    <>
      <div className="multi-select-container">
        {(selectedAttribution || []).map((item, index) => (
          <PillButton
            key={index}
            keyword={item.text}
            removeKeyword={() => removeAttribution(item.id)}
          />
        ))}
        <input
          className="multi-input"
          onChange={(e) => setInputText(e.target.value)}
          onKeyDown={addNewAttribution}
          type="text"
          value={inputText}
          placeholder="E.g. Author Name"
        />
      </div>
    </>
  );
};

export default AttributionMultiSelect;
