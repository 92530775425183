import { PUBLISH_ACTIONS } from "../actions/actionTypes";
import { PUBLISH_API_FLAG } from "../config";

const initialState = {
  isLoading: false,
  isError: false,
  status: "",
  apiFlag: null,
  toBePublishDataset: {},
  publishedDatasets: {},
  publishedDatasetDetails: {},
};

// set the state on the basis of action it recieved
export default function publishReducer(state = initialState, action) {
  switch (action.type) {
    case PUBLISH_ACTIONS.PUBLISH_DATASET_LOADING:
      return { ...state, isLoading: true, status: "" };
    case PUBLISH_ACTIONS.PUBLISH_DATASET_ADD:
      const existingResults = state.toBePublishDataset?.results || [];
      const newResults = action.payload.data.results;

      // Create a map to filter out duplicates based on a unique property (e.g., uid)
      const resultsMap = new Map();
      existingResults.concat(newResults).forEach((item) => {
        resultsMap.set(item.uid, item);
      });
      return {
        ...state,
        isLoading: false,
        toBePublishDataset: {
          ...action.payload.data,
          results: Array.from(resultsMap.values()),
        },
        status: action.payload.status,
        apiFlag: PUBLISH_API_FLAG.DATASET_LIST,
      };
    case PUBLISH_ACTIONS.RESET_PUBLISH_DATASET:
      return { ...state, toBePublishDataset: {} };
    case PUBLISH_ACTIONS.PUBLISH_DATASET_ERROR:
      return { ...state, isLoading: false, isError: true };
    case PUBLISH_ACTIONS.PUBLISH_DATASET_UPLOAD_LOADING:
      return { ...state, isLoading: true, status: "" };
    case PUBLISH_ACTIONS.PUBLISH_DATASET_UPLOAD:
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        apiFlag: PUBLISH_API_FLAG.PUBLISH_DATASET,
      };
    case PUBLISH_ACTIONS.PUBLISH_DATASET_UPLOAD_ERROR:
      return { ...state, isLoading: false, isError: true };
    case PUBLISH_ACTIONS.DATASET_LIST_LOADING:
      return { ...state, isLoading: true };
    case PUBLISH_ACTIONS.DATASET_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        publishedDatasets: { ...action.payload },
        apiFlag: PUBLISH_API_FLAG.PUBLISHED_DATASET_LIST,
      };
    case PUBLISH_ACTIONS.DATASET_LIST_ERROR:
      return { ...state, isLoading: false, isError: true };
    case PUBLISH_ACTIONS.PUBLISH_DATASET_DETAILS_LOADING:
      return { ...state, isLoading: true };
    case PUBLISH_ACTIONS.PUBLISH_DATASET_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        publishedDatasetDetails: { ...action.payload },
        apiFlag: PUBLISH_API_FLAG.PUBLISH_DATASET_DETAILS,
      };
    case PUBLISH_ACTIONS.PUBLISH_DATASET_DETAILS_ERROR:
      return { ...state, isLoading: false, isError: true };
    case PUBLISH_ACTIONS.UPDATED_DESCRIPTION_LOADING:
      return { ...state, isLoading: true };
    case PUBLISH_ACTIONS.UPDATED_DESCRIPTION_SUCCESS:
      const updatedPublishedDatasetDetails = {
        ...state.publishedDatasetDetails,
        data: {
          ...state.publishedDatasetDetails.data,
          data: {
            ...state.publishedDatasetDetails.data.data,
            description: action.payload.description,
            documentation_link: action.payload.documentation_link,
          },
        },
        apiFlag: PUBLISH_API_FLAG.PUBLISH_DATASET_DESCRIPTION,
      };
      return {
        ...state,
        isLoading: false,
        publishedDatasetDetails: updatedPublishedDatasetDetails,
      };
    case PUBLISH_ACTIONS.UPDATED_DESCRIPTION_ERROR:
      return { ...state, isLoading: false, isError: true };
    case PUBLISH_ACTIONS.UPDATED_BASIC_INFO_LOADING:
      return { ...state, isLoading: true };
    case PUBLISH_ACTIONS.UPDATED_BASIC_INFO_SUCCESS:
      const updatedBasicInfoDetails = {
        ...state.publishedDatasetDetails,
        data: {
          ...state.publishedDatasetDetails.data,
          data: {
            ...state.publishedDatasetDetails.data.data,
            title: action.payload.title,
            price_type: action.payload.price_type,
            product_cost: action.payload.product_cost,
            publisher: action.payload.publisher,
            attribution: action.payload.attribution,
            subtitle: action.payload.subtitle,
            tags: action.payload.tags,
            monthly_subcription_price: action.payload.monthly_subcription_price,
            yearly_subcription_price: action.payload.yearly_subcription_price,
          },
        },
        apiFlag: PUBLISH_API_FLAG.PUBLISH_DATASET_BASIC_INFO,
      };
      return {
        ...state,
        isLoading: false,
        publishedDatasetDetails: updatedBasicInfoDetails,
      };
    default:
      return state;
  }
}
