import { useState, useEffect } from "react";
import "../styles/publish.scss";
import Table from "./pure/tableComponent";
import { filteredPublishedDatasets, getPublishDatasetsList } from "../actions/publishActions";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { IoIosSearch } from "react-icons/io";

function Publish() {
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const publishHeader = [
    { lable: "Title", sortable: true },
    { lable: "Status", sortable: true },
    { lable: "Owner", sortable: true },
    { lable: "Pricing", sortable: true },
    { lable: "Last Action", sortable: true },
    { lable: "Actions", sortable: false },
  ];
  const filterTypes = ["All", "Published", "Draft", "Unpublished"];
  const [dropDownOpen, setDropdownOpen] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState("All");
  const [inputValue, setInputValue] = useState("");

  const dispatch = useDispatch();

  const { publish, publishedDatasets, isLoading } = useSelector((state) => ({
    publish: state.publish,
    publishedDatasets: state.publish.publishedDatasets,
    isLoading: state.publish.isLoading,
  }));

  useEffect(() => {
    if (!searchText && selectedFilterType === "All") {
      // If no filters are applied, fetch the dataset list
      dispatch(getPublishDatasetsList(page));
    } else {
      // If filters are applied, fetch the filtered dataset list
      const statusType = selectedFilterType === "All" ? "" : selectedFilterType;
      dispatch(filteredPublishedDatasets(page, searchText, statusType));
    }
  }, [page, searchText, selectedFilterType]);

  useEffect(() => {
    setPage(1); // Reset page when searchText or statusType changes
  }, [searchText, selectedFilterType]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropDownOpen);
  }

  const handleOptionClick = (type) => {
    setSelectedFilterType(type);
    setDropdownOpen(false);
  };

  // debouncing the API call
  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchText(inputValue);
    }, 300);

    return () => clearTimeout(timer);
  }, [inputValue]);

  return (
    <div>
      <div className="pulish-filters">
        <div className="search-input">
          <input
            type="text"
            placeholder="Search Dataset"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <IoIosSearch
            className="search-icon"
            size={22}
            color="#72777F"
          />
        </div>
        <div className="dropdown-section">
          <div className="filter-dropdown">
            <label>Status</label>
            <div className="custom">
              <div className="dropdown" onClick={handleDropdownToggle}>
                <span>{selectedFilterType}</span>
                <ArrowDropDownIcon />
              </div>
            </div>
          </div>
          {dropDownOpen && (
            <div className="dropdown-option">
              {filterTypes.map((type) => (
                <div className="dropdown-item" onClick={() => handleOptionClick(type)}>{type}</div>
              ))}
            </div>
          )}
        </div>
      </div>
      {/* table component accpet Array of table head and table data as props */}
      <Table
        tableHeader={publishHeader}
        tableData={publishedDatasets.results}
        isLoading={isLoading}
      />
      <div className="pagination">
        <Pagination
          count={Math.ceil(
            publishedDatasets.total_dataset / publishedDatasets.page_size
          )}
          color="primary"
          shape="rounded"
          page={page}
          siblingCount={3}
          boundaryCount={1}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default Publish;
