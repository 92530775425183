 

import { Box, Modal, Slider, Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import '../styles/Cropper.scss';
import { ImgProfileDefault } from "../images";

const boxStyle = {
  width: "300px",
  height: "300px",
  display: "flex",
  flexFlow: "column",
  justifyContent: "center",
  alignItems: "center"
};
const modalStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

const CropperModal = ({ src, modalOpen, setModalOpen, setPreview ,onProfilePicChange}) => {
  const [slideValue, setSlideValue] = useState(10);
  const cropRef = useRef(null);

//   const handleSave = async () => {
//     if (cropRef) {
//       const dataUrl = cropRef.current.getImage().toDataURL();
//       const result = await fetch(dataUrl);
//       const blob = await result.blob();
//       setPreview(URL.createObjectURL(blob));
//       setModalOpen(false);
//     }
//   };

const handleSave = ( ) => {
    if (cropRef.current) {
      const dataUrl = cropRef.current.getImage().toDataURL();
      setPreview(dataUrl);
      onProfilePicChange(dataUrl)
      setModalOpen(false);
    }
  };

  return (
    <Modal sx={modalStyle} open={modalOpen}>
      <Box sx={boxStyle}>
        <AvatarEditor
          ref={cropRef}
          image={src}
          style={{ width: "100%", height: "100%" }}
          border={50}
          borderRadius={150}
          color={[0, 0, 0, 0.72]}
          scale={slideValue / 10}
          rotate={0}
        />

        <Slider
          min={10}
          max={50}
          sx={{
            margin: "0 auto",
            width: "80%",
            color: "cyan"
          }}
          size="medium"
          defaultValue={slideValue}
          value={slideValue}
          onChange={(e) => setSlideValue(e.target.value)}
        />
        <Box
          sx={{
            display: "flex",
            padding: "10px",
            border: "3px solid white",
            background: "black"
          }}
        >
          <Button
            size="small"
            sx={{ marginRight: "10px", color: "white", borderColor: "white" }}
            variant="outlined"
            onClick={(e) => setModalOpen(false)}
            className="cancel-button"
          >
            cancel
          </Button>
          <Button
            sx={{ background: "#5596e6" }}
            size="small"
            variant="contained"
            onClick={handleSave}
            className="save-button"
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const Cropper = ({onProfilePicChange,profilePicUrl}) => {
  const [src, setSrc] = useState(profilePicUrl);
  const [preview, setPreview] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const inputRef = useRef(null);

  console.log(src,"srccheck",preview)

  const handleInputClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  const handleImgChange = (e) => {
    setSrc(URL.createObjectURL(e.target.files[0]));
    setModalOpen(true);
  };
useEffect(()=>{
setSrc(profilePicUrl)
},[profilePicUrl])
  return (
    <>
      <main className="container">
        <CropperModal
         onProfilePicChange={onProfilePicChange}
          modalOpen={modalOpen}
          src={src}
          setPreview={setPreview}
          setModalOpen={setModalOpen}
        />
        <label htmlFor="fileInput" onClick={handleInputClick}>
          <div className="img-container">
            <img
              src={src === ""?ImgProfileDefault:src
              }
              alt=""
              width="200"
              height="200"
            />
          </div>
        </label>
        <input
          id="fileInput"
          type="file"
          accept="image/*"
          ref={inputRef}
          onChange={handleImgChange}
          style={{ display: "none" }}
        />
      </main>
    </>
  );
};

export default Cropper;

 