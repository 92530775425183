import React from 'react'
import '../../styles/cardComponent.scss'
import { MdOutlineEdit } from "react-icons/md";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const CardComponent = ({ title, feilds, onEdit }) => {
    return (
        <div className='card-component'>
            <div className='card-header'>
                <p className='card-title'>{title}</p>
                <MdOutlineEdit onClick={onEdit} style={{cursor: "pointer"}} data-testid="edit-icon"/>
            </div>
            <div className='card-content'>
                {feilds.map((item, index) => (
                    <div key={index} className={`card-field ${["License", "Dataset Sample", "Ocean of Data Listing"].includes(item.label) ? "seperation-line" : ""}`}>
                        <p className="label">{item.label}</p>
                        <p className={`value ${item.value ? "with-space" : ""}`} style={item.label === "Description" ? { whiteSpace: "pre-wrap" } : {}}>{item.value}</p>
                        {item.linkText && (
                            <Link className="links">{item.linkText}</Link>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CardComponent