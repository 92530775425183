import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RESPONSIVE_BREAKPOINT, SIDEBAR_TABS } from "../../config";
import { ImgBurgerMenu, ImgOmtLogoSm } from "../../images";
import "../../styles/sidebarComponent.scss";
import MediaQuery from "react-responsive";
import SessionUtils from "../../utils/sessionUtils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SidebarComponent = (props) => {
  const { activeTab } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const history = useHistory();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isLoggedIn = SessionUtils.getToken("refresh_token");

  const handleLoggedIn = () => {
    if (!isLoggedIn) {
      history.push("/");
    }
  };

  return (
    <>
      <MediaQuery minWidth={RESPONSIVE_BREAKPOINT.minWidth.lg}>
        <img className="menu-icon" src={ImgBurgerMenu} onClick={toggleMenu} alt="menu-icon" />
        {isMenuOpen ? (
          <div className="sidebar-overlay" onClick={toggleMenu}></div>
        ) : (
          <></>
        )}
        <div className={isMenuOpen ? "hg-sidebar" : "hg-sidebar close"} role="navigation">
          <div className="logo">
            <img src={ImgOmtLogoSm} />
          </div>
          {/* <div className='heading'>menu</div> */}
          <div className="menu">
            <div className="menu-heading all-caps">datasets</div>
            <ul>
              <li
                className={activeTab === SIDEBAR_TABS.myDataSet ? "active" : ""}
                onClick={handleLoggedIn}
              >
                <Link to={"/my-datasets"}>my datasets</Link>
              </li>
              {/* <li className={activeTab === SIDEBAR_TABS.publicDatasets ? "active" : ""}><Link to={'/public-datasets'}>public datasets</Link></li> */}
            </ul>
            <div className="menu-heading all-caps">analytics</div>
            <ul>
              <li>
                <a href="/analytics/dashboard/list" target="_self">
                  {" "}
                  dashboards
                </a>
              </li>
              <li>
                <a href="/analytics/chart/list" target="_self">
                  {" "}
                  charts
                </a>
              </li>
              {/* <li><Link to={'/analytics/dashboard/list'}>dashboards</Link></li>
                            <li><Link to={'/analytics/chart/list'}>charts</Link></li> */}
            </ul>
            <div className="menu-heading all-caps">Ocean of Data</div>
            <ul>
              <li
                className={
                  activeTab === SIDEBAR_TABS.oceanDatasets ? "active" : ""
                }
              >
                <Link to={"/ocean-data"}>Ocean of Data</Link>
              </li>
              <li
                className={
                  activeTab === SIDEBAR_TABS.publishingManager ? "active" : ""
                }
              >
                <Link to={"/publishing-manager"}>Publishing Manager</Link>
              </li>
            </ul>
            <div className="menu-heading all-caps">Ocean of Things</div>
            <ul>
              <li>Hubs/Nodes</li>
              <li>Devices</li>
            </ul>
            <div className="menu-heading all-caps">Artificial Intelligence</div>
            <ul>
              <li>ML Models</li>
              <li>Training Procedures</li>
              <li>Environments</li>
            </ul>
          </div>
        </div>
      </MediaQuery>
    </>
  );
};

export default SidebarComponent;
