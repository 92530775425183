import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { ImgTBBCLogo } from "../../images";
import SidebarComponent from "./sidebarComponent";
import { SIDEBAR_TABS, VISIBILITY } from "../../config";
import "../../styles/viewAllDatasets.scss";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import SelectComponent from "./selectComponent";
import SearchBarComponent from "./searchBarComponent";
import {
  getOceanDataset,
  getOceanFilterDataset,
  setOceanDataFilter,
} from "../../actions/datasetActions";
import { useDispatch, useSelector } from "react-redux";
import { GoPlus } from "react-icons/go";
import { BsGrid } from "react-icons/bs";
import { HiOutlineViewList } from "react-icons/hi";
import GridViewDatasetComponent from "./gridViewDatasetComponent";
import ListViewDatasetComponent from "./listViewDatasetComponent";
import { Pagination } from "@mui/material";
import Loader from "../loaderComponent";
import PillButton from "./pillButton";

const ViewAllDatasets = () => {
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const [isFilter, setIsFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [isListView, setIsListView] = useState(false);
  const [filterCount, setfilterCount] = useState(0);

  const { oceanDatasets, isDatasetsListLoading, filterObj } = useSelector(
    (state) => ({
      oceanDatasets: state.dataset.oceanDatasets,
      isDatasetsListLoading: state.dataset.isDatasetsListLoading,
      filterObj: state.dataset.oceanDataFilter,
    })
  );

  useEffect(() => {
    if (!Object.keys(filterObj).length) {
      dispatch(getOceanDataset(VISIBILITY.OCEANOFDATA, page));
      setfilterCount(0);
      return;
    }

    const paramObj = {};
    let count = 0;
    Object.keys(filterObj).forEach((filterKey) => {
      if (filterObj[filterKey].length > 0) {
        paramObj[filterKey] = filterObj[filterKey].join(",");
        count += filterObj[filterKey].length;
      }
    });

    setfilterCount(count);
    dispatch(getOceanFilterDataset({ ...paramObj, page }));
  }, [filterObj, page]);

  const handleSearch = (searchText) => {
    const existingKeywords = filterObj.search_keywords || [];
    const newKeywords = searchText.split(",").filter((k) => k.trim() !== "");

    // Merge existing keywords with new ones and remove duplicates
    const mergedKeywords = Array.from(
      new Set([...existingKeywords, ...newKeywords])
    );

    const updatedFilterObj = {
      ...filterObj,
      search_keywords: mergedKeywords,
    };

    dispatch(setOceanDataFilter(updatedFilterObj));
    setPage(1);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleRemoveFilter = (filter, lable) => {
    const filterValue = filterObj[filter].filter((value) => value !== lable);
    dispatch(setOceanDataFilter({ ...filterObj, [filter]: [...filterValue] }));
  };

  return (
    <div className="view-all-datasets">
      <Link to={"/"}>
        <div className="header" style={{ paddingLeft: "128px" }}>
          <img className="big-blue-cloud" src={ImgTBBCLogo} alt="LOGO" />
        </div>
      </Link>
      <SidebarComponent activeTab={SIDEBAR_TABS.oceanDatasets} />
      <div className="main">
        <div className="ocean-data">Ocean of Data / All Datasets</div>
        <div className="all-datasets">
          <KeyboardArrowLeftIcon />
          <Link to={"/ocean-data"}><div>All Datasets</div></Link>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <button
              style={{
                background: `${filterCount > 0 ? "#ADCBEC" : "none"}`,
                border: `${filterCount > 0 ? "none" : "1px solid #72777f"}`,
                fontWeight: "400",
              }}
              className="filter-btn"
              onClick={() => setIsFilter(true)}
            >
              <GoPlus /> Filters {filterCount > 0 ? `(${filterCount})` : ""}
            </button>
            {isFilter && (
              <>
                <div className="backdrop">
                  <div className="filter-popup">
                    <SelectComponent setIsFilter={setIsFilter} />
                  </div>
                </div>
              </>
            )}
            <SearchBarComponent
              searchText={filterObj.search_keywords?.join(",") || ""}
              setSearchText={handleSearch}
            />
          </div>
          <div
            className="view"
            style={{ display: "flex", alignItems: "baseline" }}
          >
            <BsGrid
              onClick={() => setIsListView(false)}
              size={25}
              style={{
                color: `${!isListView ? "#004A75" : "#D5E4F6"}`,
                cursor: "pointer",
                marginRight: "15px",
              }}
            />
            <HiOutlineViewList
              style={{
                color: `${isListView ? "#004A75" : "#D5E4F6"}`,
                cursor: "pointer",
              }}
              onClick={() => setIsListView(true)}
              size={25}
            />
          </div>
        </div>
        <div className="active_filter">
          {Object.keys(filterObj)
            .filter((filter) => filter !== "search_keywords")
            .map((filter) => {
              return filterObj[filter].map((label, index) => (
                <PillButton
                  keyword={label}
                  removeKeyword={() => handleRemoveFilter(filter, label)}
                />
              ));
            })}
        </div>
        <div className="heading">All Datasets</div>
        {isDatasetsListLoading ? (
          <Loader />
        ) : (
          <>
            {!isListView ? (
              <div className="all-datasets-grid">
                {oceanDatasets.results.map((item) => (
                  <Link
                    key={item.uid}
                    to={`/product-listing/${item.uid}`}
                    style={{ textDecoration: "none" }}
                  >
                    <GridViewDatasetComponent
                      key={item.uid}
                      title={item.title}
                      description={item.description}
                      payment={item.price_type}
                      license={item.license}
                      dataset_format={item.dataset_format}
                      data_type={item.data_type}
                    />
                  </Link>
                ))}
              </div>
            ) : (
              <div className="all-datasets-list">
                {oceanDatasets.results.map((item) => (
                   <Link
                   key={item.uid}
                   to={`/product-listing/${item.uid}`}
                   style={{ textDecoration: "none" }}
                 >
                  <ListViewDatasetComponent
                    key={item.uid}
                    title={item.title}
                    description={item.description}
                    payment={item.price_type}
                    license={item.license}
                    data_type={item.data_type}
                  />
                </Link>
                ))}
              </div>
            )}
          </>
        )}

        {/* <div className="all-datasets-grid">
          {oceanDatasets.results.map((item) => (
            <GridViewDatasetComponent
              key={item.uid}
              title={item.name}
              description={item.description}
              payment={item.payment}
              license={item.license}
              dataset_format={item.dataset_format}
              data_type={item.data_type}
            />
          ))}
        </div> */}
        <div className="pagination">
          <Pagination
            count={Math.ceil(
              oceanDatasets.total_dataset / oceanDatasets.page_size
            )}
            color="primary"
            shape="rounded"
            page={page}
            siblingCount={3}
            boundaryCount={1}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewAllDatasets;
